<template>
  <button class="v-menu-item" @click="handleClick">
    <span class="v-menu-item__content">
      <slot />
    </span>
    <span class="v-menu-icon__append">
      <v-icon-chevron-right />
    </span>
  </button>
</template>

<script>
import VIconChevronRight from '@/components/icons/VChevronRight.vue'

export default {
  name: 'VMenuItem',
  components: { VIconChevronRight },
  props: {
    name: { type: String, required: true }
  },
  inject: ['rootMenu'],
  created() {
    this.rootMenu.addItem(this)
  },
  methods: {
    handleClick() {
      this.rootMenu.changeActiveItem(this)
    }
  }
}
</script>

<style lang="scss"></style>

const COLORS_MAP = Object.freeze({
  green: '#198038',
  yellow: '#f6ba20',
  blue: '#1a72e8',
  marsh: '#88B7B5',
  purple: '#ba54e1',
  skin: '#F4ECD6',
  brown: '#310A31',
  grayGreen: '#A7CAB1',
  gray: 'gray'
})

const LIGHT_COLORS_MAP = Object.freeze({
  lightGreen: '#bad9c3',
  lightYellow: '#fceabc',
  lightBlue: '#bad5f8'
})

const CHART_TYPES = Object.freeze({
  LINE: 'line',
  BAR: 'bar',
  ROUNDED_BAR: 'roundedBar'
})

export { COLORS_MAP, CHART_TYPES, LIGHT_COLORS_MAP }

<template>
  <svg class="icon icon--arrow-left" width="15" height="8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 3.8L3.8 0v3.1h10.3v1.3H3.8v3.2L0 3.8z" />
  </svg>
</template>

<script>
export default {
  name: 'VArrowLeft'
}
</script>

<template>
  <div :class="['date-filters-desktop', !isNmh && 'date-filters-desktop--SaaS_agent']">
    <v-popover
      :open.sync="customRangeVisible"
      :popover-base-class="`theme-${isNmh ? 'nmh' : 'agentik'} tooltip popover  date-filters-desktop__popover`"
      popover-inner-class="date-filters-desktop__popover-inner"
      popover-arrow-class="date-filters-desktop__popover-arrow"
    >
      <button
        class="date-filters-desktop__filter-button"
        :class="{
          'date-filters-desktop__filter-button--active': dateRange === $options.CUSTOM_DATE_TYPE
        }"
      >
        {{ customDateText }}
      </button>
      <template #popover>
        <v-input-date
          range
          inline
          format="DD-MM-YYYY"
          :value="customDateRange"
          @input="changeDateRange($options.CUSTOM_DATE_TYPE, $event)"
        />
      </template>
    </v-popover>
    <button
      v-for="(label, range) in $options.DATE_RANGE_TYPES"
      :key="range"
      class="date-filters-desktop__filter-button"
      :class="{ 'date-filters-desktop__filter-button--active': dateRange === range }"
      @click="changeDateRange(range)"
    >
      {{ label }}
    </button>
  </div>
</template>

<script>
import VInputDate from '@/components/common/VInputDate.vue'
import { formatDatePreview } from '@/utils/formatters'
import { CUSTOM_DATE_TYPE, DATE_RANGE_TYPES, SIX_MONTH_TYPE } from '@/constants/dateRangeTypes'
import { mapGetters } from 'vuex'
import { MODULE_SESSION } from '@/store/modules/session/session.types'

export default {
  DATE_RANGE_TYPES,
  CUSTOM_DATE_TYPE,
  name: 'VDateFiltersDesktop',
  components: { VInputDate },
  props: {
    dateRange: { type: String, default: SIX_MONTH_TYPE },
    customDateRange: { type: Array, default: () => [] }
  },
  data() {
    return {
      customRangeVisible: false
    }
  },
  computed: {
    ...mapGetters({
      isNmh: `${MODULE_SESSION}/isNmh`
    }),
    customDateText() {
      return this.dateRange === this.$options.CUSTOM_DATE_TYPE
        ? formatDatePreview(this.customDateRange[0], this.customDateRange[1])
        : 'Выберите дату'
    }
  },
  methods: {
    changeDateRange(dateRange, customDateRange) {
      this.$emit('change', { dateRange, customDateRange })
      this.customRangeVisible = false
    }
  }
}
</script>

<template>
  <div class="v-menu">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'VMenu',
  props: {
    value: { type: String, default: '' }
  },
  provide() {
    return {
      rootMenu: this
    }
  },
  data() {
    return {
      items: [],
      activeItem: null
    }
  },
  watch: {
    value: {
      handler(val) {
        this.setActiveItemByName(val)
      }
    }
  },
  mounted() {
    if (this.items.length && this.value) {
      this.setActiveItemByName(this.value)
    }
  },
  methods: {
    addItem(item) {
      this.items.push(item)
    },
    setActiveItemByName(name) {
      const item = this.items.find(el => el.name === name)
      if (item) this.activeItem = item
    },
    changeActiveItem(item) {
      this.activeItem = item
      this.$emit('input', item.name)
    }
  }
}
</script>

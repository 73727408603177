<template>
  <svg class="icon icon--filters" width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <!-- eslint-disable vue/max-len -->
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11 11.083h2v9.167h-2v-9.167zM20.025 20.249h-2.043v-3.661h-1.998v-1.844h6.038v1.844h-1.997v3.66zM18 3.75h2v9.167h-2V3.75zM6.02 20.265H4.062V12.94H2v-1.855h6.05v1.854H6.02v7.326zM14.98 7.417V9.25H9V7.417h2.006V3.75h1.965v3.667h2.009zM4 3.75h2v5.5H4v-5.5z"
    />
  </svg>
</template>

<script>
export default {
  name: 'VIconFilters'
}
</script>

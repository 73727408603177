<template>
  <span class="form-field__error edit-owner-dialog__error" :class="{ 'edit-owner-dialog__error--info': isInfo }">
    <template v-if="errorMessage">{{ errorMessage }}</template>
    <template v-else>
      {{ phoneSearchInfo }}
      <v-button v-if="clientLinkByRole" class="edit-owner-dialog__error-button" is-text :to="clientLinkByRole"
        >Открыть</v-button
      >
    </template>
  </span>
</template>

<script>
import { CALL_ROLES } from '@/constants/callsRoles'
import VButton from '@/components/common/VButton.vue'

const ROLES_MAP = {
  owner: 'Собственник',
  customer: 'Покупатель',
  agent: 'Агент',
  admin: 'Администратор'
}

export default {
  name: 'VFormUserError',
  components: { VButton },
  props: {
    errorMessage: { type: String, default: '' },
    callRole: { type: String, default: '' },
    user: { type: [String, Object], default: () => null }
  },
  computed: {
    isInfo() {
      return this.user && (typeof this.user === 'string' || this.user.role === this.callRole)
    },
    phoneSearchInfo() {
      const { role } = this.user ?? {}
      const map = {
        '': !this.user,
        'Совпадений не найдено. Заполните все поля.': typeof this.user === 'string',
        [`${ROLES_MAP[role]} с таким номером уже существует.`]: !this.callRole,
        [`Найден ${ROLES_MAP[role]} с таким же номером. Добавление невозможно.`]: ![
          CALL_ROLES.CUSTOMER,
          CALL_ROLES.OWNER
        ].includes(role),
        [`Найден ${ROLES_MAP[role]} с таким же номером. Смените вкладку.`]: role !== this.callRole
      }

      const message = Object.entries(map).find(entry => entry[1])
      return (message && message[0]) ?? 'Найдено совпадение. Некоторые данные автозаполнены.'
    },
    clientLinkByRole() {
      if (this.user && typeof this.user !== 'string' && !this.callRole) {
        const { role, id } = this.user

        const ROLE_LINKS_MAP = {
          [CALL_ROLES.CUSTOMER]: { name: 'customer-edit', params: { customerId: id } },
          [CALL_ROLES.OWNER]: { name: 'owner-edit', params: { ownerId: id } }
        }

        if ([CALL_ROLES.CUSTOMER, CALL_ROLES.OWNER].includes(role)) {
          return ROLE_LINKS_MAP[role]
        }
      }
      return null
    }
  },
  methods: {
    getClientLinkByRole(role, id) {
      const ROLE_LINKS_MAP = {
        [CALL_ROLES.CUSTOMER]: { name: 'customer-edit', params: { customerId: id } },
        [CALL_ROLES.OWNER]: { name: 'owner-edit', params: { ownerId: id } }
      }
      if ([CALL_ROLES.CUSTOMER, CALL_ROLES.OWNER].includes(role)) {
        return ROLE_LINKS_MAP[role]
      }
      return null
    }
  }
}
</script>

<style lang="scss">
.edit-owner-dialog__error {
  margin-top: 10px;
  &--info {
    color: #979797;
  }

  &-button {
    color: inherit;

    &:hover {
      color: inherit;
      text-decoration: underline;
    }
  }
}
</style>

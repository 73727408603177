<template>
  <div class="agent-metrics">
    <div class="agent-metrics__header">
      <h3 class="agent-metrics__title">{{ title }}</h3>
      <v-input-date
        v-if="isMonthSelectVisible"
        v-model="dateModel"
        :editable="false"
        type="month"
        value-type="date"
        format="MMMM YYYY"
        class="agent-metrics__date-picker"
      />
    </div>
    <div class="agent-metrics__list">
      <agent-metrics-list-item v-for="(item, index) in list" :key="item.id" :agent="{ ...item, index }" />
    </div>
  </div>
</template>

<script>
import VInputDate from '@/components/common/VInputDate.vue'
import AgentMetricsListItem from '@/components/Agent/Metrics/ListItem.vue'

export default {
  name: 'AgentMetricsList',
  components: { VInputDate, AgentMetricsListItem },
  props: {
    title: {
      type: String,
      default: null
    },
    date: {
      type: Date,
      default: () => new Date()
    },
    list: {
      type: Array,
      default: () => []
    },
    isMonthSelectVisible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    dateModel: {
      get() {
        return this.date
      },
      set(newDate) {
        this.$emit('change', newDate)
      }
    }
  }
}
</script>

<template>
  <match-media v-slot="{ mobile }">
    <div class="dashboard-objects-realty">
      <v-button-go-back
        v-if="mobile && localSectionActiveMenuItem"
        class="dashboard-general__button-go-back"
        @click="goBack"
      />

      <template v-if="!localSectionActiveMenuItem">
        <!-- Adverts chart -->
        <v-chart-with-filters
          class="dashboard-objects-realty__chart"
          type="line"
          title="Новые объекты"
          :count="adverts.total"
          :datasets="adverts.datasets"
          :labels="adverts.labels"
          :titles="adverts.titles"
          :filters="adverts.filters"
          with-similar-range
          with-date-filter
          with-agent-select
          with-plan-fact
          @change-filters="changeAdvertsFilters"
        />

        <!-- Adverts categories chart -->
        <v-chart-with-filters
          class="dashboard-objects-realty__chart"
          type="line"
          title="Количество активных объектов"
          :count="advertsCategories.total"
          :datasets="advertsCategories.datasets"
          :labels="advertsCategories.labels"
          :filters="advertsCategories.filters"
          :chart-options="{ legend: true }"
          with-date-filter
          with-agent-select
          @change-filters="changeAdvertsCategoriesFilters"
        />

        <!-- Adverts agents chart -->
        <v-chart-with-filters
          class="dashboard-objects-realty__chart"
          type="bar"
          title="Объекты. Агенты"
          :datasets="advertsAgents.datasets"
          :labels="advertsAgents.labels"
          :chart-options="{ legend: true }"
        />

        <!-- Adverts average price chart -->
        <v-chart-with-filters
          class="dashboard-objects-realty__chart"
          type="bar"
          :title="mobile ? 'Средняя стоимость' : 'Анализ средней стоимости объекта среди агентов'"
          :datasets="advertsAveragePrice.datasets"
          :labels="advertsAveragePrice.labels"
          :chart-options="{ legend: true, yAxesRound: true }"
          units="млн"
        />
      </template>
      <!-- agent-metrics section -->
      <div
        v-if="!mobile || localSectionActiveMenuItem === $options.TOP_AGENTS"
        class="dashboard-objects-realty__agent-metrics-section"
      >
        <agent-metrics-list
          title="Лучшие агенты. Объекты."
          :list="agentBestList"
          :date="dateAgentBestList"
          is-month-select-visible
          class="dashboard-objects-realty__agent-metrics-list dashboard-objects-realty__agent-metrics-list--left"
          @change="refetchBestAgentList"
        ></agent-metrics-list>
        <agent-metrics-list
          title="Неэффективные агенты. Объекты."
          :list="agentIneffectiveList"
          :date="dateAgentIneffectiveList"
          is-month-select-visible
          class="dashboard-objects-realty__agent-metrics-list dashboard-objects-realty__agent-metrics-list--right"
          @change="refetchIneffectiveAgentList"
        ></agent-metrics-list>
      </div>

      <div v-if="mobile && !localSectionActiveMenuItem" class="dashboard-objects-realty__mobile-menu">
        <v-menu v-model="localSectionActiveMenuItem">
          <v-menu-item :name="$options.TOP_AGENTS">
            <span class="dashboard-objects-realty__menu-item">
              ТОП агентов
            </span>
          </v-menu-item>
        </v-menu>
      </div>
    </div>
  </match-media>
</template>

<script>
import { MatchMedia } from 'vue-component-media-queries'
import VChartWithFilters from '@/components/common/VChartWithFilters.vue'
import AgentMetricsList from '@/components/Agent/Metrics/List.vue'
import VMenu from '@/components/common/VMenu.vue'
import VMenuItem from '@/components/common/VMenuItem.vue'
import VButtonGoBack from '@/components/common/VButtonGoBack.vue'
import { loadingService } from '@/services/loading'
import { TOP_AGENTS } from '@/constants/dashboard'
import { advertsService, statisticService } from '@/services/http'
import { CUSTOM_DATE_TYPE, SIX_MONTH_TYPE, THIS_MONTH_TYPE } from '@/constants/dateRangeTypes'
import { abbreviatedDate, createChartDataset } from '@/utils/common'
import { roundMoneyWithoutPrefix } from '@/utils/formatters'
import { getColorByIndex } from '../common/VChart/utils'

export default {
  TOP_AGENTS,
  name: 'DashboardObjectsRealty',
  components: {
    MatchMedia,
    AgentMetricsList,
    VMenu,
    VMenuItem,
    VButtonGoBack,
    VChartWithFilters
  },
  props: {
    sectionActiveMenuItem: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      adverts: {
        filters: {
          dateFrom: null,
          dateTo: null,
          dateRangeType: SIX_MONTH_TYPE,
          isSimilarRange: false,
          planFact: false,
          agentId: null
        },
        total: 0,
        datasets: [],
        labels: [],
        titles: {
          current: [],
          similar: []
        }
      },
      advertsCategories: {
        filters: {
          dateFrom: null,
          dateTo: null,
          dateRangeType: SIX_MONTH_TYPE,
          agentId: null
        },
        total: 0,
        datasets: [],
        labels: []
      },
      advertsAgents: {
        datasets: [],
        labels: []
      },
      advertsAveragePrice: {
        datasets: [],
        labels: []
      },
      categories: [],

      agentBestList: [],
      agentIneffectiveList: [],
      dateAgentBestList: new Date(),
      dateAgentIneffectiveList: new Date()
    }
  },
  computed: {
    localSectionActiveMenuItem: {
      get() {
        return this.sectionActiveMenuItem
      },
      set(newValue) {
        this.$emit('update:sectionActiveMenuItem', newValue)
      }
    }
  },
  created() {
    loadingService.setViewLoading(true)

    this.fetchCategories()
      .then(() => {
        const advertsRequest = this.fetchAdvertsGraph()
        const advertsCategoriesRequest = this.fetchAdvertsCategoriesGraph()
        const advertsAgentsRequest = this.fetchAdvertsAgentsGraph()
        const advertsAveragePriceRequest = this.fetchAdvertsAgentsAveragePriceGraph()
        const agentBestList = this.fetchBestAgentList()
        const agentIneffectiveList = this.fetchIneffectiveAgentList()

        return Promise.all([
          advertsRequest,
          advertsCategoriesRequest,
          advertsAgentsRequest,
          advertsAveragePriceRequest,
          agentBestList,
          agentIneffectiveList
        ])
      })
      .finally(() => {
        loadingService.setViewLoading(false)
      })
  },
  inject: ['graphsMainColor'],
  methods: {
    fetchBestAgentList(params) {
      return statisticService.getBestAgentsAdvertList(params).then(list => {
        this.agentBestList = list
      })
    },

    fetchIneffectiveAgentList(params) {
      return statisticService.getIneffectiveAgentsAdvertList(params).then(list => {
        this.agentIneffectiveList = list
      })
    },

    refetchBestAgentList(newDate) {
      this.dateAgentBestList = newDate

      const month = newDate.getMonth() + 1
      const year = newDate.getFullYear()

      loadingService.setViewLoading(true)
      this.fetchBestAgentList({ month, year }).finally(() => {
        loadingService.setViewLoading(false)
      })
    },

    refetchIneffectiveAgentList(newDate) {
      this.dateAgentIneffectiveList = newDate

      const month = newDate.getMonth() + 1
      const year = newDate.getFullYear()

      loadingService.setViewLoading(true)
      this.fetchIneffectiveAgentList({ month, year }).finally(() => {
        loadingService.setViewLoading(false)
      })
    },
    fetchCategories() {
      return advertsService.selectCategory().then(data => {
        this.categories = data
      })
    },

    // fetch Graph
    fetchAdvertsGraph() {
      return statisticService.getAdvertsGraph(this.adverts.filters).then(data => {
        const datasets = []

        const defaultDataset = createChartDataset({
          data: data.graph,
          color: this.graphsMainColor,
          label: 'Объекты',
          filled: true
        })
        datasets.push(defaultDataset)

        if (data.similarRangeGraph) {
          const similarDataset = createChartDataset({
            data: data.similarRangeGraph,
            color: 'green',
            label: 'Объекты в похожем периоде'
          })
          datasets.push(similarDataset)
          this.adverts.titles.similar = data.similarRangeGraph.map(i => i.date)
        } else {
          this.adverts.titles.similar = []
        }

        if (data.planFact) {
          const planDataset = createChartDataset({
            data: data.planFact,
            color: 'blue',
            label: 'План объектов'
          })
          datasets.push(planDataset)
        }

        this.adverts.datasets = datasets
        this.adverts.labels = data.graph.map(i => i.date)
        this.adverts.titles.current = data.graph.map(i => i.date)
        this.adverts.total = data.sumOfValues

        if (
          this.adverts.filters.dateRangeType !== THIS_MONTH_TYPE &&
          this.adverts.filters.dateRangeType !== CUSTOM_DATE_TYPE
        ) {
          this.adverts.labels = abbreviatedDate(this.adverts.labels)
          this.adverts.titles.current = abbreviatedDate(this.adverts.titles.current)
          this.adverts.titles.similar = abbreviatedDate(this.adverts.titles.similar)
        }
      })
    },
    fetchAdvertsCategoriesGraph() {
      return statisticService.getAdvertsCategoriesGraph(this.advertsCategories.filters).then(data => {
        const datasets = []

        // eslint-disable-next-line
        data.graphs.forEach(({ graph, advert_Category }, index) => {
          // eslint-disable-next-line
          const category = this.categories.find(cat => cat.id === advert_Category)
          if (!category) return

          const label = category.name
          const dataset = createChartDataset({ data: graph, color: getColorByIndex(index), label })
          datasets.push(dataset)
        })

        this.advertsCategories.datasets = datasets
        this.advertsCategories.labels = data.graphs.length ? data.graphs[0].graph.map(i => i.date) : []
        this.advertsCategories.total = data.sumOfValues

        if (
          this.advertsCategories.filters.dateRangeType !== THIS_MONTH_TYPE &&
          this.advertsCategories.filters.dateRangeType !== CUSTOM_DATE_TYPE
        ) {
          this.advertsCategories.labels = abbreviatedDate(this.advertsCategories.labels)
        }
      })
    },
    fetchAdvertsAgentsGraph() {
      return statisticService.getAdvertsAgentsGraph().then(data => {
        const datasets = []

        data.graphs.forEach(({ graph, agent }, index) => {
          const label = agent?.name
          const dataset = createChartDataset({ data: graph, color: getColorByIndex(index), label })
          datasets.push(dataset)
        })

        this.advertsAgents.datasets = datasets
        const labels = data.graphs.length ? data.graphs[0].graph.map(i => i.date) : []
        this.advertsAgents.labels = abbreviatedDate(labels)
      })
    },
    fetchAdvertsAgentsAveragePriceGraph() {
      return statisticService.getAdvertsAgentsAveragePriceGraph().then(data => {
        const datasets = []

        data.forEach(({ graph, agent }, index) => {
          const label = agent.name
          const dataset = createChartDataset({ data: graph, color: getColorByIndex(index), label })

          dataset.data = dataset?.data.map(roundMoneyWithoutPrefix)

          datasets.push(dataset)
        })

        this.advertsAveragePrice.datasets = datasets
        const labels = data.length ? data[0].graph.map(i => i.date) : []
        this.advertsAveragePrice.labels = abbreviatedDate(labels)
      })
    },

    // refetch Graph
    refetchAdvertGraph() {
      loadingService.setViewLoading(true)

      this.fetchAdvertsGraph().finally(() => {
        loadingService.setViewLoading(false)
      })
    },
    refetchAdvertsCategoriesGraph() {
      loadingService.setViewLoading(true)

      this.fetchAdvertsCategoriesGraph().finally(() => {
        loadingService.setViewLoading(false)
      })
    },

    changeAdvertsFilters(filters) {
      this.adverts.filters = filters
      this.refetchAdvertGraph()
    },
    changeAdvertsCategoriesFilters(filters) {
      this.advertsCategories.filters = filters
      this.refetchAdvertsCategoriesGraph()
    },
    goBack() {
      this.localSectionActiveMenuItem = undefined
    }
  }
}
</script>

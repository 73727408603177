<template>
  <div class="statistic-info-block">
    <div v-if="showChangeBadge" :class="[`statistic-info-block__badge`, `statistic-info-block__badge--${badgeColor}`]">
      {{ changeString }}
    </div>
    <div class="statistic-info-block__title">{{ title }}</div>
    <div class="statistic-info-block__value">
      <span v-if="$slots.prepend" class="statistic-info-block__prepend"> <slot name="prepend" /> </span>
      <slot name="value"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VStatisticInfoBlock',
  props: {
    title: { type: String, required: true },
    change: { type: Number, default: null }
  },
  computed: {
    showChangeBadge() {
      return this.change !== null && this.change !== undefined
    },
    badgeColor() {
      if (this.change > 0) return 'green'
      if (this.change < 0) return 'red'
      return 'yellow'
    },
    changeString() {
      const absoluteValue = Math.abs(this.change)
      if (this.change > 0) return `+ ${absoluteValue}%`
      if (this.change < 0) return `- ${absoluteValue}%`
      return `${absoluteValue}%`
    }
  }
}
</script>

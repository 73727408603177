<template>
  <label :class="['v-checkbox', disabled && 'v-checkbox--disabled', reverse && 'v-checkbox--reverse']">
    <input
      v-model="model"
      type="checkbox"
      :true-value="trueValue"
      :false-value="falseValue"
      class="v-checkbox__input"
      :disabled="disabled"
    />
    <span class="v-checkbox__fake"></span>
    <span class="v-checkbox__title">
      <slot></slot>
    </span>
  </label>
</template>

<script>
export default {
  name: 'VCheckbox',
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    checked: { type: [Boolean, String, Object, Number, Array], default: null },
    disabled: { type: Boolean, default: false },
    reverse: { type: Boolean, default: false },
    trueValue: { type: [String, Boolean, Object, Array, Number], default: true },
    falseValue: { type: [String, Boolean, Object, Array, Number], default: false }
  },
  computed: {
    model: {
      get() {
        return this.checked
      },
      set(value) {
        this.$emit('change', value)
      }
    }
  }
}
</script>

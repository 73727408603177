<template>
  <match-media v-slot="{ mobile }">
    <div class="dashboard-general">
      <v-button-go-back
        v-if="mobile && localSectionActiveMenuItem"
        class="dashboard-general__button-go-back"
        @click="goBack"
      />

      <div v-if="!mobile || !localSectionActiveMenuItem" class="dashboard-general__filters-wrapper">
        <dashboard-general-filters-mobile
          v-if="mobile"
          :visible.sync="filtersMobileVisible"
          :default-filters="filtersParams"
          @change="changeFilters"
        />
        <dashboard-general-filters
          v-if="!mobile"
          :default-filters="filtersParams"
          class="dashboard-general__filters-desktop"
          @change="changeFilters"
        />
        <v-button v-else class="dashboard-general__button-filters" @click="openFiltersMobile">
          <v-icon-filters class="dashboard-general__button-icon dashboard-general__button-icon--filters" />
          Фильтры
        </v-button>
      </div>

      <dashboard-general-summary-section
        v-if="!localSectionActiveMenuItem"
        :values="summaryValues"
        class="dashboard-general__summary-section"
      />

      <div v-if="!localSectionActiveMenuItem" class="dashboard-general__line-chart-section">
        <!-- Calls chart -->
        <v-chart-with-filters
          class="dashboard-general__chart"
          type="line"
          title="Общее кол-во лидов"
          :count="calls.total"
          :datasets="calls.datasets"
          :labels="calls.labels"
          :titles="calls.titles"
          :filters="{ ...calls.filters, ...filtersParams }"
          with-similar-range
          @change-filters="changeCallsFilters"
        />

        <!-- Shows chart -->
        <v-chart-with-filters
          class="dashboard-general__chart"
          type="line"
          title="Общее кол-во показов"
          :count="shows.total"
          :datasets="shows.datasets"
          :labels="shows.labels"
          :titles="shows.titles"
          :filters="{ ...shows.filters, ...filtersParams }"
          with-similar-range
          with-plan-fact
          @change-filters="changeShowsFilters"
        />

        <!-- Adverts chart -->
        <v-chart-with-filters
          class="dashboard-general__chart"
          type="line"
          title="Общее кол-во новых объектов"
          :count="adverts.total"
          :datasets="adverts.datasets"
          :labels="adverts.labels"
          :titles="adverts.titles"
          :filters="{ ...adverts.filters, ...filtersParams }"
          with-similar-range
          with-plan-fact
          @change-filters="changeAdvertsFilters"
        />
      </div>

      <!-- agent-metrics section -->
      <div
        v-if="!mobile || localSectionActiveMenuItem === $options.TOP_AGENTS"
        class="dashboard-general__agent-metrics-section"
      >
        <agent-metrics-list
          title="Лучшие агенты"
          :list="agentBestList"
          class="dashboard-general__agent-metrics-list dashboard-general__agent-metrics-list--left"
        ></agent-metrics-list>
        <agent-metrics-list
          title="Неэффективные агенты"
          :list="agentIneffectiveList"
          class="dashboard-general__agent-metrics-list dashboard-general__agent-metrics-list--right"
        ></agent-metrics-list>
      </div>

      <div v-if="mobile && !localSectionActiveMenuItem" class="dashboard-general__mobile-menu">
        <v-menu v-model="localSectionActiveMenuItem">
          <v-menu-item :name="$options.TOP_AGENTS">
            <span class="dashboard-general__menu-item">
              ТОП агентов
            </span>
          </v-menu-item>
        </v-menu>
      </div>
    </div>
  </match-media>
</template>

<script>
import { MatchMedia } from 'vue-component-media-queries'
import DashboardGeneralFilters from '@/components/Dashboard/Filters.vue'
import DashboardGeneralFiltersMobile from '@/components/Dashboard/FiltersMobile.vue'
import DashboardGeneralSummarySection from '@/components/common/SummarySection.vue'
import AgentMetricsList from '@/components/Agent/Metrics/List.vue'
import VButton from '@/components/common/VButton.vue'
import VIconFilters from '@/components/icons/VFilters.vue'
import VChartWithFilters from '@/components/common/VChartWithFilters.vue'
import VMenu from '@/components/common/VMenu.vue'
import VMenuItem from '@/components/common/VMenuItem.vue'
import VButtonGoBack from '@/components/common/VButtonGoBack.vue'
import { loadingService } from '@/services/loading'
import { statisticService } from '@/services/http'
import { createChartDataset, abbreviatedDate } from '@/utils/common'
import { SIX_MONTH_TYPE, THIS_MONTH_TYPE, CUSTOM_DATE_TYPE } from '@/constants/dateRangeTypes'
import { TOP_AGENTS } from '@/constants/dashboard'

export default {
  TOP_AGENTS,
  name: 'DashboardGeneral',
  components: {
    MatchMedia,
    VButton,
    DashboardGeneralFilters,
    DashboardGeneralFiltersMobile,
    DashboardGeneralSummarySection,
    AgentMetricsList,
    VIconFilters,
    VChartWithFilters,
    VMenu,
    VMenuItem,
    VButtonGoBack
  },
  props: {
    sectionActiveMenuItem: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      summary: null,
      filtersMobileVisible: false,
      filtersParams: {
        dateTo: null,
        dateFrom: null,
        dateRangeType: null,
        categoryId: null
      },
      calls: {
        filters: {
          isSimilarRange: false
        },
        total: 0,
        datasets: [],
        labels: [],
        titles: {
          current: [],
          similar: []
        }
      },
      shows: {
        filters: {
          isSimilarRange: false,
          planFact: false
        },
        total: 0,
        datasets: [],
        labels: [],
        titles: {
          current: [],
          similar: []
        }
      },
      adverts: {
        filters: {
          isSimilarRange: false,
          planFact: false
        },
        total: 0,
        datasets: [],
        labels: [],
        titles: {
          current: [],
          similar: []
        }
      },
      agentBestList: [],
      agentIneffectiveList: []
    }
  },
  inject: ['graphsMainColor'],
  computed: {
    summaryValues() {
      if (!this.summary) return []

      const { activeAdverts, newAdverts, calls, shows } = this.summary

      return [
        {
          title: 'Активных объектов',
          current: activeAdverts.current || 0,
          previous: activeAdverts.previous || 0,
          change: activeAdverts.percentageChange || 0
        },
        {
          title: 'Новых объектов',
          current: newAdverts.current || 0,
          previous: newAdverts.previous || 0,
          change: newAdverts.percentageChange || 0
        },
        {
          title: 'Количество лидов',
          current: calls.current || 0,
          previous: calls.previous || 0,
          change: calls.percentageChange || 0
        },
        {
          title: 'Количество показов',
          current: shows.current || 0,
          previous: shows.previous || 0,
          change: shows.percentageChange || 0
        }
      ]
    },
    localSectionActiveMenuItem: {
      get() {
        return this.sectionActiveMenuItem
      },
      set(newValue) {
        this.$emit('update:sectionActiveMenuItem', newValue)
      }
    }
  },
  created() {
    if (!this.filtersParams.dateRangeType) this.filtersParams.dateRangeType = SIX_MONTH_TYPE
    this.fetchGeneralData()
  },
  methods: {
    changeFilters(filters) {
      this.filtersParams = { ...filters }
      this.fetchGeneralData()
    },

    fetchGeneralData() {
      loadingService.setViewLoading(true)

      const summaryRequest = statisticService.getSummaryInformation(this.filtersParams).then(summary => {
        this.summary = summary
      })
      const callsRequest = this.fetchCallsGraph()
      const showsRequest = this.fetchShowsGraph()
      const advertsRequest = this.fetchAdvertsGraph()
      const agentBestList = this.fetchBestAgentList()
      const agentIneffectiveList = this.fetchIneffectiveAgentList()

      Promise.all([
        summaryRequest,
        callsRequest,
        showsRequest,
        advertsRequest,
        agentBestList,
        agentIneffectiveList
      ]).finally(() => {
        loadingService.setViewLoading(false)
      })
    },

    // fetch Graph
    fetchCallsGraph() {
      return statisticService.getCallsGraph({ ...this.calls.filters, ...this.filtersParams }).then(data => {
        const datasets = []

        const defaultDataset = createChartDataset({
          data: data.graph,
          color: this.graphsMainColor,
          label: 'Лиды',
          filled: true
        })
        datasets.push(defaultDataset)

        if (data.similarRangeGraph) {
          const similarDataset = createChartDataset({
            data: data.similarRangeGraph,
            color: 'green',
            label: 'Лиды в похожем периоде'
          })
          datasets.push(similarDataset)
          this.calls.titles.similar = data.similarRangeGraph.map(i => i.date)
        } else {
          this.calls.titles.similar = []
        }

        this.calls.datasets = datasets
        this.calls.labels = data.graph.map(i => i.date)

        this.calls.titles.current = data.graph.map(i => i.date)
        this.calls.total = data.sumOfValues

        if (
          this.filtersParams.dateRangeType !== THIS_MONTH_TYPE &&
          this.filtersParams.dateRangeType !== CUSTOM_DATE_TYPE
        ) {
          this.calls.labels = abbreviatedDate(this.calls.labels)
          this.calls.titles.current = abbreviatedDate(this.calls.titles.current)
          this.calls.titles.similar = abbreviatedDate(this.calls.titles.similar)
        }
      })
    },
    fetchShowsGraph() {
      return statisticService.getShowsGraph({ ...this.shows.filters, ...this.filtersParams }).then(data => {
        const datasets = []

        const defaultDataset = createChartDataset({
          data: data.graph,
          color: this.graphsMainColor,
          label: 'Показы',
          filled: true
        })
        datasets.push(defaultDataset)

        if (data.similarRangeGraph) {
          const similarDataset = createChartDataset({
            data: data.similarRangeGraph,
            color: 'green',
            label: 'Показы в похожем периоде'
          })
          datasets.push(similarDataset)
          this.shows.titles.similar = data.similarRangeGraph.map(i => i.date)
        } else {
          this.shows.titles.similar = []
        }

        if (data.planGraph) {
          const planDataset = createChartDataset({ data: data.planGraph, color: 'blue', label: 'План показов' })
          datasets.push(planDataset)
        }

        this.shows.datasets = datasets
        this.shows.labels = data.graph.map(i => i.date)
        this.shows.titles.current = data.graph.map(i => i.date)
        this.shows.total = data.sumOfValues

        if (
          this.filtersParams.dateRangeType !== THIS_MONTH_TYPE &&
          this.filtersParams.dateRangeType !== CUSTOM_DATE_TYPE
        ) {
          this.shows.labels = abbreviatedDate(this.shows.labels)
          this.shows.titles.current = abbreviatedDate(this.shows.titles.current)
          this.shows.titles.similar = abbreviatedDate(this.shows.titles.similar)
        }
      })
    },
    fetchAdvertsGraph() {
      return statisticService.getAdvertsGraph({ ...this.adverts.filters, ...this.filtersParams }).then(data => {
        const datasets = []

        const defaultDataset = createChartDataset({
          data: data.graph,
          color: this.graphsMainColor,
          label: 'Новые объекты',
          filled: true
        })
        datasets.push(defaultDataset)

        if (data.similarRangeGraph) {
          const similarDataset = createChartDataset({
            data: data.similarRangeGraph,
            color: 'green',
            label: 'Новые объекты в похожем периоде'
          })
          datasets.push(similarDataset)
          this.adverts.titles.similar = data.similarRangeGraph.map(i => i.date)
        } else {
          this.adverts.titles.similar = []
        }

        if (data.planGraph) {
          const planDataset = createChartDataset({
            data: data.planGraph,
            color: 'blue',
            label: 'План новых объектов'
          })
          datasets.push(planDataset)
        }

        this.adverts.datasets = datasets
        this.adverts.labels = data.graph.map(i => i.date)
        this.adverts.titles.current = data.graph.map(i => i.date)
        this.adverts.total = data.sumOfValues

        if (
          this.filtersParams.dateRangeType !== THIS_MONTH_TYPE &&
          this.filtersParams.dateRangeType !== CUSTOM_DATE_TYPE
        ) {
          this.adverts.labels = abbreviatedDate(this.adverts.labels)
          this.adverts.titles.current = abbreviatedDate(this.adverts.titles.current)
          this.adverts.titles.similar = abbreviatedDate(this.adverts.titles.similar)
        }
      })
    },

    // refetch Graph
    refetchCallsGraph() {
      loadingService.setViewLoading(true)

      this.fetchCallsGraph().finally(() => {
        loadingService.setViewLoading(false)
      })
    },
    refetchShowsGraph() {
      loadingService.setViewLoading(true)

      this.fetchShowsGraph().finally(() => {
        loadingService.setViewLoading(false)
      })
    },
    refetchAdvertsGraph() {
      loadingService.setViewLoading(true)

      this.fetchAdvertsGraph().finally(() => {
        loadingService.setViewLoading(false)
      })
    },

    fetchBestAgentList() {
      return statisticService.getBestAgentsList(this.filtersParams).then(list => {
        this.agentBestList = list
      })
    },
    fetchIneffectiveAgentList() {
      return statisticService.getIneffectiveAgentsList(this.filtersParams).then(list => {
        this.agentIneffectiveList = list
      })
    },

    // change graph filters
    changeCallsFilters(filters) {
      this.calls.filters = filters
      this.refetchCallsGraph()
    },
    changeShowsFilters(filters) {
      this.shows.filters = filters
      this.refetchShowsGraph()
    },
    changeAdvertsFilters(filters) {
      this.adverts.filters = filters
      this.refetchAdvertsGraph()
    },

    openFiltersMobile() {
      this.filtersMobileVisible = true
    },

    goBack() {
      this.localSectionActiveMenuItem = undefined
    }
  }
}
</script>

export const CUSTOM_DATE_TYPE = 'CUSTOM_DATE'
export const SIX_MONTH_TYPE = 'SIX_MONTH'
export const THIS_MONTH_TYPE = 'THIS_MONTH'
export const ALL_TIME_TYPE = 'ALL_TIME'

export const DATE_RANGE_TYPES = {
  [THIS_MONTH_TYPE]: 'Месяц',
  QUARTER: 'Квартал',
  [SIX_MONTH_TYPE]: '6 мес',
  YEAR: 'Год',
  [ALL_TIME_TYPE]: 'За все время'
}

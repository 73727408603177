import Chart from 'chart.js'
import { tooltipTopPosition, roundedTopRectangle, dateAdapter } from './chart.extends'

Chart.defaults.global.defaultFontColor = '#B4B6BD'
Chart.defaults.global.defaultFontFamily = 'Roboto'
Chart.defaults.global.defaultFontSize = 11

Chart.Tooltip.positioners.top = tooltipTopPosition

Chart.controllers.roundedBar = Chart.controllers.bar.extend({
  dataElementType: roundedTopRectangle
})
// eslint-disable-next-line
Chart._adapters._date.override(dateAdapter)

export default Chart

<template>
  <div class="v-chart" :class="`v-chart--${type}`">
    <canvas ref="chart" class="v-chart__canvas" aria-label="Chart" role="complementary"></canvas>
    <div v-if="!isDatasetsExist" class="v-chart__no-data">Недостаточно данных</div>
  </div>
</template>

<script>
import { isEqual } from '@/utils/common'
import Chart from './chart'
import {
  createGradient,
  colorToHex,
  createCommonOptions,
  createTooltipInner,
  createTooltip,
  getTooltipBounding,
  getTooltipBackgroundBounding,
  getTooltipElementBounding
} from './utils'
import { CHART_TYPES } from './constants'

function removeListener(func, type = 'click') {
  return document.removeEventListener(type, func)
}

function hideTooltip(event) {
  if (event.target !== this.$refs.chart && this.tooltip) {
    this.tooltip.style.display = 'none'
    removeListener(this.bindedHideTooltip)
  }
}

export default {
  name: 'VChart',
  props: {
    hideLegend: { type: Boolean, default: false },
    moneyRound: { type: Boolean, default: false },
    withSort: { type: Boolean, default: false },
    datasets: { type: Array, default: () => [] },
    type: { type: String, required: true, validator: val => Object.values(CHART_TYPES).includes(val) },
    labels: { type: Array, required: true },
    titles: { type: Object, default: () => {} },
    units: { type: String, default: '' },
    chartOptions: { type: Object, default: null }
  },
  inject: ['mediaQueries'],
  data() {
    return {
      ctx: null,
      chart: null,
      tooltip: null,
      bindedHideTooltip: null
    }
  },
  computed: {
    isDatasetsExist() {
      return this.datasets.length > 0 && this.datasets.some(dataset => dataset.data?.length > 0)
    },
    isBarChart() {
      return this.type === CHART_TYPES.BAR || this.type === CHART_TYPES.ROUNDED_BAR
    },
    isMobile() {
      return this.mediaQueries.mobile
    },
    chartType() {
      if (!this.isMobile && this.type === CHART_TYPES.BAR) {
        return 'roundedBar'
      }

      return this.type
    },
    formattedDatasets() {
      return this.datasets.map(dataset => {
        const hex = colorToHex(dataset.color)
        return {
          data: dataset.data,
          label: dataset.label,
          borderColor: hex,
          backgroundColor: this.isBarChart ? hex : dataset.filled && createGradient(hex, this.ctx),
          fill: !!dataset.filled,
          pointBackgroundColor: hex,
          pointRadius: 0,
          borderWidth: 2,
          barPercentage: dataset.barPercentage || 1,
          categoryPercentage: dataset.categoryPercentage || 0.7,
          barThickness: dataset.barThickness,
          minBarLength: 1
        }
      })
    },
    chartCommonOptions() {
      return createCommonOptions({
        xAxesType: this.chartOptions.xAxesType,
        xAxesTime: this.chartOptions.xAxesTime,
        xAxesOffset: this.isBarChart,
        yAxesRound: this.chartOptions.yAxesRound,
        yAxesRoundMoney: this.chartOptions.yAxesRoundMoney,
        yAxesBeginAtZero: this.chartOptions.yAxesBeginAtZero,
        stacked: this.isMobile || this.chartOptions.stacked,
        hideLegend: this.hideLegend,
        units: this.units
      })
    }
  },
  watch: {
    datasets() {
      this.chart.data.datasets = this.formattedDatasets
      this.update()
    },
    labels() {
      this.chart.data.labels = this.labels
      this.update()
    },
    isMobile() {
      this.createChart()
    },
    chartOptions: {
      handler(val, oldVal) {
        if (!isEqual(val, oldVal)) {
          this.chart.options = { ...this.chart.options, ...this.chartCommonOptions }
          this.update()
        }
      }
    }
  },
  created() {
    this.bindedHideTooltip = hideTooltip.bind(this)
  },
  mounted() {
    this.createChart()
  },
  beforeDestroy() {
    if (this.tooltip) this.tooltip.remove()
    removeListener(this.bindedHideTooltip)
  },
  methods: {
    createChart() {
      this.ctx = this.$refs.chart.getContext('2d')
      if (this.chart) this.chart.destroy()

      this.chart = new Chart(this.ctx, {
        type: this.chartType,
        data: {
          datasets: this.formattedDatasets,
          labels: this.labels
        },
        options: {
          ...this.chartCommonOptions,
          tooltips: {
            enabled: false,
            mode: 'index',
            intersect: false,
            position: 'top',
            itemSort: (a, b) => b.yLabel - a.yLabel,
            custom: this.createCustomTooltip()
          }
        }
      })
    },
    update() {
      this.chart.update({ duration: 500 })
    },
    createCustomTooltip() {
      const self = this

      // eslint-disable-next-line func-names
      return function(tooltipModel) {
        document.addEventListener('click', self.bindedHideTooltip)
        // Create element on first render
        if (!self.tooltip) {
          self.tooltip = createTooltip()
          document.body.appendChild(self.tooltip)
        }
        // Hide if no tooltip
        if (tooltipModel.opacity === 0) {
          self.tooltip.style.display = 'none'
          return
        }
        self.tooltip.style.display = 'unset'

        // Set Text
        if (tooltipModel.body) {
          const innerHtml = createTooltipInner(
            tooltipModel,
            self.units,
            self.titles,
            self.chartOptions.isNotLabelDate,
            self.moneyRound,
            self.withSort
          )
          const tooltipEl = self.tooltip.querySelector('.v-chart__tooltip')
          const tableRoot = tooltipEl.querySelector('.v-chart__tooltip-inner')
          tableRoot.innerHTML = innerHtml
        }

        // Display, position
        // eslint-disable-next-line no-underscore-dangle
        const chart = this._chart

        const tooltipBounding = getTooltipBounding(chart)
        self.tooltip.style.left = `${tooltipBounding.left}px`
        self.tooltip.style.top = `${tooltipBounding.top}px`
        self.tooltip.style.width = `${tooltipBounding.width}px`
        self.tooltip.style.height = `${tooltipBounding.height}px`

        const tooltipElementBounding = getTooltipElementBounding(tooltipModel, self.tooltip, chart, self.isMobile)
        const tooltipEl = self.tooltip.querySelector('.v-chart__tooltip')
        const tooltipArrow = self.tooltip.querySelector('.v-chart__tooltip-arrow')
        tooltipEl.style.left = `${tooltipElementBounding.left}px`
        tooltipEl.style.top = `${tooltipElementBounding.top}px`
        tooltipArrow.style.left = `${tooltipElementBounding.arrowLeft}%`

        const backgroundEl = self.tooltip.querySelector('.v-chart__tooltip-background')
        const backgroundBounding = getTooltipBackgroundBounding(tooltipModel, self.tooltip, chart, self.isMobile)
        backgroundEl.style.width = `${backgroundBounding.width}px`
        backgroundEl.style.left = `${backgroundBounding.left}px`
      }
    }
  }
}
</script>

import Chart from 'chart.js'
import moment from 'moment'
import { translateDate } from '@/components/common/VChart/utils'

const tooltipTopPosition = elements => {
  // eslint-disable-next-line no-underscore-dangle
  const x = elements.reduce((acc, el) => acc + el._model.x, 0) / elements.length
  const y = Math.min.apply(
    null,
    // eslint-disable-next-line no-underscore-dangle
    elements.map(el => el._model.y || el._view.base)
  )
  return { x, y }
}

// draws a rectangle with a rounded top
const drawRoundedTopRectangle = (ctx, x, y, width, height, radius) => {
  ctx.beginPath()
  ctx.moveTo(x + radius, y)
  // top right corner
  ctx.lineTo(x + width - radius, y)
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius)
  // bottom right   corner
  ctx.lineTo(x + width, y + height)
  // bottom left corner
  ctx.lineTo(x, y + height)
  // top left
  ctx.lineTo(x, y + radius)
  ctx.quadraticCurveTo(x, y, x + radius, y)
  ctx.closePath()
}

const roundedTopRectangle = Chart.elements.Rectangle.extend({
  draw() {
    // eslint-disable-next-line no-underscore-dangle
    const { ctx } = this._chart
    // eslint-disable-next-line no-underscore-dangle
    const vm = this._view
    let left
    let right
    let top
    let bottom
    let signX
    let signY
    let borderSkipped
    let { borderWidth } = vm

    if (!vm.horizontal) {
      // bar
      left = vm.x - vm.width / 2
      right = vm.x + vm.width / 2
      top = vm.y
      bottom = vm.base
      signX = 1
      signY = bottom > top ? 1 : -1
      borderSkipped = vm.borderSkipped || 'bottom'
    } else {
      // horizontal bar
      left = vm.base
      right = vm.x
      top = vm.y - vm.height / 2
      bottom = vm.y + vm.height / 2
      signX = right > left ? 1 : -1
      signY = 1
      borderSkipped = vm.borderSkipped || 'left'
    }

    // Canvas doesn't allow us to stroke inside the width so we can
    // adjust the sizes to fit if we're setting a stroke on the line
    if (borderWidth) {
      // borderWidth shold be less than bar width and bar height.
      const barSize = Math.min(Math.abs(left - right), Math.abs(top - bottom))
      borderWidth = borderWidth > barSize ? barSize : borderWidth
      const halfStroke = borderWidth / 2
      // Adjust borderWidth when bar top position is near vm.base(zero).
      const borderLeft = left + (borderSkipped !== 'left' ? halfStroke * signX : 0)
      const borderRight = right + (borderSkipped !== 'right' ? -halfStroke * signX : 0)
      const borderTop = top + (borderSkipped !== 'top' ? halfStroke * signY : 0)
      const borderBottom = bottom + (borderSkipped !== 'bottom' ? -halfStroke * signY : 0)
      // not become a vertical line?
      if (borderLeft !== borderRight) {
        top = borderTop
        bottom = borderBottom
      }
      // not become a horizontal line?
      if (borderTop !== borderBottom) {
        left = borderLeft
        right = borderRight
      }
    }

    // calculate the bar width and roundess
    const barWidth = Math.abs(left - right)
    // eslint-disable-next-line no-underscore-dangle
    const roundness = this._chart.config.options.barRoundness || 0.5
    const radius = barWidth * roundness * 0.5

    // keep track of the original top of the bar
    const prevTop = top

    // move the top down so there is room to draw the rounded top
    top = prevTop + radius
    const barRadius = top - prevTop

    ctx.beginPath()

    ctx.fillStyle = vm.backgroundColor
    ctx.strokeStyle = vm.borderColor
    ctx.lineWidth = borderWidth

    // draw the rounded top rectangle
    drawRoundedTopRectangle(ctx, left, top - barRadius + 1, barWidth, bottom - prevTop, barRadius)

    ctx.fill()
    if (borderWidth) {
      ctx.stroke()
    }

    // restore the original top value so tooltips and scales still work
    top = prevTop
  }
})

const FORMATS = {
  datetime: 'MMM D, YYYY, h:mm:ss a',
  millisecond: 'h:mm:ss.SSS a',
  second: 'h:mm:ss a',
  minute: 'h:mm a',
  hour: 'hA',
  day: 'MMM D',
  week: 'll',
  month: 'MMM YYYY',
  quarter: '[Q]Q - YYYY',
  year: 'YYYY'
}

const dateAdapter = {
  formats() {
    return FORMATS
  },

  parse(value, format) {
    if (typeof value === 'string' && typeof format === 'string') {
      // eslint-disable-next-line
      value = moment(value, format)
    } else if (!(value instanceof moment)) {
      // eslint-disable-next-line
      value = moment(value)
    }
    return value.isValid() ? value.valueOf() : null
  },

  format(time, format) {
    const formattedStr = moment(time).format(format)
    const translatedStr = translateDate(formattedStr)
    return translatedStr
  },

  add(time, amount, unit) {
    return moment(time)
      .add(amount, unit)
      .valueOf()
  },

  diff(max, min, unit) {
    return moment(max).diff(moment(min), unit)
  },

  startOf(time, unit, weekday) {
    // eslint-disable-next-line
    time = moment(time)
    if (unit === 'isoWeek') {
      // eslint-disable-next-line
      weekday = Math.trunc(Math.min(Math.max(0, weekday), 6))
      return time
        .isoWeekday(weekday)
        .startOf('day')
        .valueOf()
    }
    return time.startOf(unit).valueOf()
  },

  endOf(time, unit) {
    return moment(time)
      .endOf(unit)
      .valueOf()
  }
}

export { tooltipTopPosition, roundedTopRectangle, dateAdapter }

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{staticClass:"shows-create-dialog",attrs:{"visible":_vm.localVisible,"title":_vm.title},on:{"update:visible":function($event){_vm.localVisible=$event}}},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{attrs:{"body-scroll-lock-ignore":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-form-row',[_c('v-form-field',{attrs:{"label":"КЛИЕНТ","rules":_vm.rules.account,"error-message":_vm.errors.account},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-select',{attrs:{"label":"name","append-to-body":"","disabled":_vm.isCreation && !!_vm.clientId,"filterable":false,"is-error":!!validationErrors.length,"options":_vm.clientOptions,"loading":_vm.clientOptionsLoading},on:{"search":_vm.onClientSearch},model:{value:(_vm.show.account),callback:function ($$v) {_vm.$set(_vm.show, "account", $$v)},expression:"show.account"}})]}}],null,true)})],1),(_vm.isRoleAdmin)?_c('v-form-row',[_c('v-form-field',{attrs:{"label":"АГЕНТ","rules":_vm.rules.agent,"error-message":_vm.errors.agent},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-select',{attrs:{"label":"name","append-to-body":"","is-error":!!validationErrors.length,"options":_vm.agentOptions,"loading":_vm.agentOptionsLoading},model:{value:(_vm.show.agent),callback:function ($$v) {_vm.$set(_vm.show, "agent", $$v)},expression:"show.agent"}})]}}],null,true)})],1):_vm._e(),_c('v-form-row',[(_vm.isCreation)?_c('v-form-field',{attrs:{"label":"ОБЪЕКТЫ НЕДВИЖИМОСТИ","rules":_vm.rules.advert,"separate-label":"","hide-errors":"","error-message":_vm.errors.adverts},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-form-dynamic-select',{attrs:{"label":"name","append-to-body":"","is-error":!!validationErrors.length,"errors":validationErrors,"options":_vm.advertOptions,"prevent-duplicates":"","loading":_vm.advertOptionsLoading},on:{"search":_vm.onAdvertSearch},model:{value:(_vm.show.adverts),callback:function ($$v) {_vm.$set(_vm.show, "adverts", $$v)},expression:"show.adverts"}})]}}],null,true)}):_c('v-form-field',{attrs:{"label":"ОБЪЕКТ НЕДВИЖИМОСТИ","rules":_vm.rules.advert,"separate-label":"","hide-errors":"","error-message":_vm.errors.advert},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-select',{attrs:{"label":"name","append-to-body":"","is-error":!!validationErrors.length,"options":_vm.advertOptions},on:{"search":_vm.onAdvertSearch},model:{value:(_vm.show.advert),callback:function ($$v) {_vm.$set(_vm.show, "advert", $$v)},expression:"show.advert"}})]}}],null,true)})],1),_c('v-form-row',[_c('v-form-field',{attrs:{"label":"ДАТА ПОКАЗА","rules":_vm.rules.showDate,"error-message":_vm.errors.showDate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-input-date',{staticClass:"shows-create-dialog__date-input",attrs:{"clearable":false,"format":"DD.MM.YYYY","value-type":"format","is-error":!!validationErrors.length},model:{value:(_vm.show.showDate),callback:function ($$v) {_vm.$set(_vm.show, "showDate", $$v)},expression:"show.showDate"}})]}}],null,true)})],1),_c('v-form-row',[_c('v-form-field',{attrs:{"label":"ВРЕМЯ ПОКАЗА","rules":_vm.rules.showTime,"separate-label":"","error-message":_vm.errors.showTime},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('div',{staticClass:"shows-create-dialog__time"},[_c('v-select',{staticClass:"shows-create-dialog__time-select",attrs:{"label":"name","append-to-body":"","is-error":!!validationErrors.length,"options":_vm.$options.HOURS,"clearable":false},model:{value:(_vm.show.showTimeHours),callback:function ($$v) {_vm.$set(_vm.show, "showTimeHours", $$v)},expression:"show.showTimeHours"}}),_c('span',[_vm._v(":")]),_c('v-select',{staticClass:"shows-create-dialog__time-select",attrs:{"label":"name","append-to-body":"","is-error":!!validationErrors.length,"options":_vm.$options.MINUTES,"clearable":false},model:{value:(_vm.show.showTimeMinutes),callback:function ($$v) {_vm.$set(_vm.show, "showTimeMinutes", $$v)},expression:"show.showTimeMinutes"}})],1)]}}],null,true)})],1),_c('v-form-row',[_c('v-form-field',{attrs:{"label":"КОММЕНТАРИЙ","rules":_vm.rules.description,"error-message":_vm.errors.description},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-textarea',{attrs:{"is-error":!!validationErrors.length,"rows":3},model:{value:(_vm.show.description),callback:function ($$v) {_vm.$set(_vm.show, "description", $$v)},expression:"show.description"}})]}}],null,true)})],1),_c('v-form-row',[_c('v-image-uploader-with-preview',{staticClass:"shows-create-dialog__image-uploader",attrs:{"photos":_vm.show.photos},on:{"change":_vm.changePhotos,"add":_vm.addPhoto},scopedSlots:_vm._u([{key:"preview",fn:function(ref){
var photo = ref.photo;
return [_c('v-act-of-viewing-preview',{attrs:{"blob-image":photo.image,"url":photo.url,"file-name":photo.fileName,"progress":photo.progress},on:{"remove":function($event){return _vm.removePhoto(photo)}}})]}},{key:"input-text",fn:function(){return [_vm._v("Загрузить фото акта просмотра")]},proxy:true}],null,true)})],1)]},proxy:true},{key:"footer",fn:function(){return [(!_vm.isCreation && _vm.showData.status !== 'canceled')?_c('v-button',{staticClass:"shows-create-dialog__cancel-button",on:{"click":_vm.cancelShow}},[_vm._v("Отменить показ")]):_vm._e(),_c('v-button',{attrs:{"disabled":!_vm.isCreation && _vm.isFieldsNotChanged,"primary":"","type":"submit"}},[_vm._v("Сохранить")])]},proxy:true}],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <match-media v-slot="{ mobile }">
    <div class="dashboard">
      <v-breadcrumbs
        v-if="!mobile && activeTab !== $options.GENERAL"
        :items="breadcrumbItems"
        class="realty-edit__breadcrumbs"
      />

      <div v-if="!sectionActiveMenuItem" class="dashboard__header">
        <v-page-title class="dashboard__title">{{ pageTitle }}</v-page-title>
        <span v-if="mobile" class="dashboard__title-tab">{{ $options.ACTIVE_TAB_ITEMS[activeTab] }}</span>
      </div>
      <div class="dashboard__tabs-wrapper">
        <v-tabs v-if="!sectionActiveMenuItem" v-model="activeTab" :primary="mobile" class="dashboard__tabs">
          <v-tab v-for="(label, key) of $options.ACTIVE_TAB_ITEMS" :key="key" :name="key">{{ label }}</v-tab>
        </v-tabs>
      </div>
      <div class="dashboard__section-wrapper">
        <dashboard-general
          v-if="activeTab === $options.GENERAL"
          :section-active-menu-item.sync="sectionActiveMenuItem"
        />
        <dashboard-calls
          v-if="activeTab === $options.CALLS"
          ref="dashboardCalls"
          :section-active-menu-item.sync="sectionActiveMenuItem"
        />
        <dashboard-shows
          v-if="activeTab === $options.SHOWS"
          ref="dashboardShows"
          :section-active-menu-item.sync="sectionActiveMenuItem"
        />
        <dashboard-objects-realty
          v-if="activeTab === $options.OBJECTS"
          :section-active-menu-item.sync="sectionActiveMenuItem"
        />
      </div>
    </div>

    <calls-create-dialog
      v-if="isRoleAdmin"
      :visible.sync="editDialogCallsVisible"
      :call-data="editDialogModel"
      @submitted="reloadPageCalls"
    ></calls-create-dialog>
    <shows-create-dialog
      :visible.sync="editDialogShowsVisible"
      :show-data="editDialogModel"
      @submitted="reloadPageShows"
    ></shows-create-dialog>
  </match-media>
</template>

<script>
import { MatchMedia } from 'vue-component-media-queries'
import VPageTitle from '@/components/common/VPageTitle.vue'
import VTabs from '@/components/common/VTabs.vue'
import VTab from '@/components/common/VTab.vue'
import DashboardGeneral from '@/components/Dashboard/General.vue'
import DashboardCalls from '@/components/Dashboard/Calls.vue'
import DashboardShows from '@/components/Dashboard/Shows.vue'
import DashboardObjectsRealty from '@/components/Dashboard/ObjectsRealty.vue'
import VBreadcrumbs from '@/components/common/VBreadcrumbs.vue'
import CallsCreateDialog from '@/components/Calls/CreateDialog.vue'
import ShowsCreateDialog from '@/components/Shows/CreateDialog.vue'
import { mapGetters } from 'vuex'
import { GENERAL, CALLS, OBJECTS, SHOWS, ACTIVE_TAB_ITEMS } from '@/constants/dashboard'
import { MODULE_SESSION } from '@/store/modules/session/session.types'

const TITLES = {
  [CALLS]: 'Анализ лидов',
  [OBJECTS]: 'Анализ объектов',
  [SHOWS]: 'Анализ показов'
}

export default {
  ACTIVE_TAB_ITEMS,
  GENERAL,
  CALLS,
  OBJECTS,
  SHOWS,
  name: 'Dashboard',
  components: {
    ShowsCreateDialog,
    CallsCreateDialog,
    MatchMedia,
    VPageTitle,
    VTabs,
    VTab,
    VBreadcrumbs,
    DashboardGeneral,
    DashboardCalls,
    DashboardShows,
    DashboardObjectsRealty
  },
  inject: ['mediaQueries'],
  data() {
    return {
      editDialogModel: {},
      editDialogCallsVisible: false,
      editDialogShowsVisible: false
    }
  },
  provide() {
    return {
      graphsMainColor: this.graphsMainColor
    }
  },
  computed: {
    ...mapGetters({
      isRoleAdmin: `${MODULE_SESSION}/isRoleAdmin`,
      isNmh: `${MODULE_SESSION}/isNmh`
    }),
    graphsMainColor() {
      return this.isNmh ? 'yellow' : 'blue'
    },
    activeTab: {
      get() {
        return this.$route.query.tab || GENERAL
      },
      set(val) {
        if (this.$route.query.tab) this.$router.push({ query: { ...this.$route.query, tab: val } })
        else this.$router.replace({ query: { tab: val } })
      }
    },
    sectionActiveMenuItem: {
      get() {
        return this.$route.query.menuItem
      },
      set(val) {
        window.scrollTo(0, 0)
        this.$router.push({ query: { ...this.$route.query, menuItem: val } })
      }
    },
    breadcrumbItems() {
      return [
        {
          text: 'Аналитика агентства',
          to: { name: 'dashboard' }
        },
        {
          text: TITLES[this.activeTab],
          disabled: true
        }
      ]
    },
    pageTitle() {
      return this.mediaQueries.mobile || this.activeTab === GENERAL
        ? 'Аналитика агентства'
        : `Аналитика агентства. ${TITLES[this.activeTab]}`
    }
  },
  methods: {
    reloadPageCalls() {
      this.$refs.dashboardCalls.refetchCallsList()
    },
    reloadPageShows() {
      this.$refs.dashboardShows.refetchShowsList()
    }
  }
}
</script>

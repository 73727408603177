<template>
  <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 8a8 8 0 1 0 16 0A8 8 0 0 0 0 8Zm1.067 0a6.933 6.933 0 1 1 13.866 0A6.933 6.933 0 0 1 1.067 8Z"
      fill="#404042"
    />
    <path
      d="M11 5.604 10.396 5 8 7.396 5.604 5 5
      5.604 7.396 8 5 10.396l.604.604L8 8.604 10.396 11l.604-.604L8.604 8 11 5.604Z"
      fill="#404042"
    />
  </svg>
</template>

<script>
export default {
  name: 'VNotSuccess'
}
</script>

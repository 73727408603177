<template>
  <svg class="icon icon--chevron-right" width="7" height="11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.3 0L0 1.3l4.3 4.2L0 9.7 1.3 11 7 5.5 1.3 0z" />
  </svg>
</template>

<script>
export default {
  name: 'VChevronRight'
}
</script>

<template>
  <v-dialog :visible.sync="localVisible" :title="title" class="calls-create-dialog">
    <validation-observer v-slot="{ handleSubmit }">
      <v-form body-scroll-lock-ignore @submit.prevent="handleSubmit(onSubmit)">
        <!--      todo: повторяется шаблон в OwnerEditPendingDialogFormContentWrapper, вынести в отдельный компонент-->
        <template #default>
          <v-form-row>
            <v-form-field label="ТИП КОНТАКТА" :rules="rules.role" :error-message="errors.role">
              <v-tabs v-model="call.role" class="calls-create-dialog__role-tabs" primary>
                <v-tab
                  v-for="(role, index) of $options.CALL_ROLE_MAP"
                  :key="index"
                  :disabled="!isCreation"
                  :name="role.value"
                >
                  {{ role.label }}
                </v-tab>
              </v-tabs>
            </v-form-field>
          </v-form-row>

          <v-form-row>
            <v-form-field label="ТЕЛЕФОН КЛИЕНТА" :rules="rules.phone" :error-message="errors.phone">
              <template #default="{ validationErrors }">
                <v-input-phone
                  v-model="call.phone"
                  :is-error="!!validationErrors.length"
                  placeholder="8(800) 800-80-80"
                >
                  <template v-if="successAppend" #append>
                    <v-success v-if="successAppend === 'success'" class="calls-create-dialog__success-icon"></v-success>
                    <v-not-success v-else></v-not-success>
                  </template>
                </v-input-phone>
              </template>
              <template #error="{ errorMessage }">
                <v-form-user-error :error-message="errorMessage" :user="phoneSearchUser" :call-role="call.role" />
              </template>
            </v-form-field>
          </v-form-row>

          <v-form-row>
            <v-form-field label="ИМЯ КЛИЕНТА" :rules="rules.name" :error-message="errors.name">
              <template #default="{ validationErrors }">
                <v-input v-model="call.name" :is-error="!!validationErrors.length" type="text" placeholder="Имя" />
              </template>
            </v-form-field>
          </v-form-row>

          <v-form-row v-if="isRoleAdmin">
            <v-form-field label="АГЕНТ" :rules="rules.agent" :error-message="errors.agent">
              <template #default="{ validationErrors }">
                <v-select
                  v-model="call.agent"
                  label="name"
                  append-to-body
                  :is-error="!!validationErrors.length"
                  :options="agentOptions"
                  :loading="agentOptionsLoading"
                />
              </template>
            </v-form-field>
          </v-form-row>

          <v-form-row>
            <v-form-field label="ИСТОЧНИК ЛИДА" :rules="rules.callSource" :error-message="errors.callSource">
              <template #default="{ validationErrors }">
                <v-select
                  v-model="call.callSource"
                  label="name"
                  append-to-body
                  :is-error="!!validationErrors.length"
                  :options="callSourceOptions"
                />
              </template>
            </v-form-field>
          </v-form-row>

          <v-form-row>
            <v-form-field label="ДАТА" :rules="rules.callDate" :error-message="errors.callDate">
              <template #default="{ validationErrors }">
                <v-input-date
                  v-model="call.callDate"
                  class="calls-create-dialog__date-input"
                  :clearable="false"
                  format="DD.MM.YYYY"
                  is-disabled-dates-after-current-date
                  value-type="format"
                  :is-error="!!validationErrors.length"
                />
              </template>
            </v-form-field>
          </v-form-row>

          <v-form-row v-if="call.role === $options.CALL_ROLES.CUSTOMER">
            <v-form-field
              label="ОБЪЕКТ НЕДВИЖИМОСТИ"
              :rules="rules.advert"
              separate-label
              hide-errors
              :error-message="errors.advert"
            >
              <template #default="{ validationErrors }">
                <v-select
                  v-model="call.advert"
                  append-to-body
                  label="name"
                  :is-error="!!validationErrors.length"
                  :options="advertOptions"
                  @search="onAdvertSearch"
                ></v-select>
              </template>
            </v-form-field>
          </v-form-row>
          <v-form-row>
            <v-form-field label="КОММЕНТАРИЙ" :rules="rules.text" :error-message="errors.text">
              <template #default="{ validationErrors }">
                <v-textarea v-model="call.text" :is-error="!!validationErrors.length" :rows="3" />
              </template>
            </v-form-field>
          </v-form-row>
        </template>

        <template #footer>
          <v-button :disabled="!isCreation && isFieldsNotChanged" primary type="submit">Добавить звонок</v-button>
        </template>
      </v-form>
    </validation-observer>
  </v-dialog>
</template>

<script>
import VDialog from '@/components/common/VDialog.vue'
import VForm from '@/components/form/VForm.vue'
import VFormRow from '@/components/form/VFormRow.vue'
import VFormField from '@/components/form/VFormField.vue'
import VInput from '@/components/common/VInput.vue'
import VInputPhone from '@/components/common/VInputPhone.vue'
import VButton from '@/components/common/VButton.vue'
import VSelect from '@/components/common/VSelect.vue'
import { advertsService, agentsService, authService, ownersService } from '@/services/http'
import { formatAdvertName, formatAgentObject } from '@/utils/formatters'
import { getFirstErrorForFields, isFieldsNotChanged } from '@/utils/common'
import { loadingService } from '@/services/loading'
import { mapGetters } from 'vuex'
import { MODULE_SESSION } from '@/store/modules/session/session.types'
import VTab from '@/components/common/VTab.vue'
import VTabs from '@/components/common/VTabs.vue'
import VInputDate from '@/components/common/VInputDate.vue'
import { CALL_ROLE_MAP, CALL_ROLES } from '@/constants/callsRoles'
import VTextarea from '@/components/common/VTextarea.vue'
import VFormUserError from '@/components/form/VFormUserError.vue'
import VSuccess from '@/components/icons/VSuccess.vue'
import VNotSuccess from '@/components/icons/VNotSuccess.vue'

function getEmptyCall() {
  return {
    role: CALL_ROLES.CUSTOMER,
    phone: '',
    name: '',
    agent: null,
    callSource: null,
    callDate: null,
    advert: null,
    text: ''
  }
}

export default {
  CALL_ROLE_MAP,
  CALL_ROLES,
  name: 'CallsCreateDialog',
  components: {
    VNotSuccess,
    VSuccess,
    VFormUserError,
    VTextarea,
    VInputDate,
    VTabs,
    VTab,
    VDialog,
    VFormField,
    VFormRow,
    VButton,
    VInput,
    VForm,
    VSelect,
    VInputPhone
  },
  props: {
    visible: { type: Boolean, required: true },
    callData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data: () => {
    return {
      call: getEmptyCall(),
      loading: false,
      errors: {},
      advertOptionsLoading: false,
      agentOptionsLoading: false,
      advertOptions: [],
      agentOptions: [],
      callSourceOptions: [],
      phoneSearchUser: null
    }
  },
  computed: {
    isFieldsNotChanged() {
      return isFieldsNotChanged(this.callData, this.call, { isAdmin: this.isRoleAdmin, objectType: 'call' })
    },
    successAppend() {
      if (this.phoneSearchUser) {
        if (typeof this.phoneSearchUser === 'string' || this.phoneSearchUser.role === this.callRole) {
          return 'success'
        }
        return 'not success'
      }
      return ''
    },
    callPhone() {
      return this.call.phone
    },
    callRole() {
      return this.call.role
    },
    ...mapGetters({
      isRoleAdmin: `${MODULE_SESSION}/isRoleAdmin`
    }),
    rules() {
      return {
        role: `${this.isCreation ? 'required' : ''}`,
        phone: `${this.isCreation ? 'required' : ''}`,
        name: `${this.isCreation ? 'required' : ''}`,
        agent: `${this.isCreation ? 'required' : ''}`,
        callSource: `${this.isCreation ? 'required' : ''}`,
        callDate: `${this.isCreation ? 'required' : ''}`,
        advert: `${this.isCreation ? 'required' : ''}`,
        text: ''
      }
    },
    isCreation() {
      return !(this.callData && this.callData.id)
    },
    title() {
      return this.isCreation ? 'Новый лид' : 'Редактировать лид'
    },
    localVisible: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {
    callRole: {
      handler(val) {
        if (this.phoneSearchUser) {
          const { role, name } = this.phoneSearchUser
          if (role && val === role) {
            this.call.name = name
          }
        }
      }
    },
    callPhone: {
      handler(val) {
        if (val?.length === 11 && val !== this.callData?.account?.phone) {
          authService.select({ phone: val }).then(({ results }) => {
            this.phoneSearchUser = results[0] || 'noMatches'
            if (results[0] && results[0].role === this.call.role) {
              this.call.name = results[0].name
            }
          })
        } else {
          this.phoneSearchUser = null
        }
      }
    },
    loading(val) {
      loadingService.setGlobalLoading(val)
    },
    callData: {
      immediate: true,
      handler() {
        this.resetLocalState()
      }
    },
    visible: {
      immediate: true,
      handler(val) {
        if (val) this.resetLocalState()
      }
    }
  },
  created() {
    if (this.isRoleAdmin) {
      this.selectAgents()
    }
    this.selectAdverts()
    this.selectCallSources()
  },
  methods: {
    selectAgents() {
      this.agentOptionsLoading = true
      return agentsService
        .select()
        .then(agentList => {
          this.agentOptions = agentList.map(agent => formatAgentObject(agent))
        })
        .finally(() => {
          this.agentOptionsLoading = false
        })
    },
    selectAdverts(query) {
      this.advertOptionsLoading = true
      return advertsService
        .select(query)
        .then(advertsList => {
          this.advertOptions = advertsList.map(advert => {
            const { name, roundedPrice } = advert
            return {
              ...advert,
              name: `${name} - ${roundedPrice}`
            }
          })
        })
        .finally(() => {
          this.advertOptionsLoading = false
        })
    },
    selectCallSources() {
      return ownersService.getCallSourcesMap().then(callSourcesList => {
        this.callSourceOptions = callSourcesList
      })
    },
    resetLocalState() {
      if (Object.keys(this.callData).length) {
        const { account, ...restModel } = this.callData
        const {
          profile: { agent },
          ...restField
        } = account
        this.call = {
          ...restModel,
          ...restField,
          agent,
          advert: { ...this.callData?.advert, name: formatAdvertName(this.callData?.advert, this.isCreation) }
        }
      } else {
        this.call = getEmptyCall()
      }

      this.errors = {}
    },
    onSubmit() {
      this.loading = true

      const submitMethod = this.isCreation
        ? agentsService.createCall(this.call, this.phoneSearchUser === 'noMatches')
        : agentsService.updateCall(this.callData.id, this.call)

      submitMethod
        .then(() => {
          this.$emit('submitted')
          this.localVisible = false
        })
        .catch(error => {
          const flatErrors = { ...error.details, ...error.details.account.profile }
          this.errors = getFirstErrorForFields(flatErrors)
        })
        .finally(() => {
          this.loading = false
        })
    },
    onAdvertSearch(query, loading) {
      loading(true)
      this.selectAdverts(query).finally(() => {
        loading(false)
      })
    }
  }
}
</script>

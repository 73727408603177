<template>
  <li class="agent-metrics-item">
    <div class="agent-metrics-item__id">{{ agent.index + 1 }}</div>
    <v-avatar class="agent-metrics-item__avatar" size="small" :src="agentPhoto" />
    <div class="agent-metrics-item__name">{{ agent.name }}</div>
    <div class="agent-metrics-item__count">{{ agent.count }}</div>
  </li>
</template>

<script>
import VAvatar from '@/components/common/VAvatar.vue'

export default {
  name: 'AgentMetricsListItem',
  components: {
    VAvatar
  },
  props: {
    agent: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    agentPhoto() {
      return this.agent?.photo
    }
  }
}
</script>

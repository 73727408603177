<template>
  <div class="summary-section">
    <statistic-info-block
      v-for="(item, index) in values"
      :key="index"
      :title="item.title"
      :change="item.change"
      class="summary-section__info"
      :class="{ 'summary-section__info--arrowed': arrowed && index % 2 === 0 }"
    >
      <template v-if="item.prepend" #prepend>{{ item.prepend }}</template>
      <template v-if="hasItemCurrentAndPrevious(item)" #value>
        <span class="summary-section__value summary-section__value--current">{{ item.current }}</span>
        <span class="summary-section__value summary-section__value--previous">{{ item.previous }}</span>
      </template>
      <template v-else #value>{{ item.value }}</template>
    </statistic-info-block>
  </div>
</template>

<script>
import StatisticInfoBlock from '@/components/common/VStatisticInfoBlock.vue'
import { isNumber } from '@/utils/common'

export default {
  name: 'SummarySection',
  components: { StatisticInfoBlock },
  props: {
    values: { type: Array, required: true },
    arrowed: { type: Boolean, default: false }
  },
  methods: {
    hasItemCurrentAndPrevious(item) {
      return isNumber(item.current) && isNumber(item.previous)
    }
  }
}
</script>

<template>
  <button class="button-back" v-bind="$attrs" v-on="$listeners">
    <v-icon-arrow-left />
    Назад
  </button>
</template>

<script>
import VIconArrowLeft from '@/components/icons/VArrowLeft.vue'

export default {
  name: 'VButtonGoBack',
  components: { VIconArrowLeft }
}
</script>

<template>
  <div class="form-dynamic-select">
    <v-select
      v-for="(item, index) in valuesToShow"
      :key="index"
      class="form-dynamic-select__select"
      :is-error="isError"
      :value="item"
      v-bind="$attrs"
      :options="filteredOptions"
      @input="updateValue(index, $event)"
      v-on="inheritListeners"
    />

    <v-form-error v-if="isError">{{ errors[0] }}</v-form-error>

    <div v-if="!hideControls && localValue.length > 0" class="form-dynamic-select__controls">
      <v-button
        v-if="localValue.length > 0 && !emptyFieldVisible && lessThanMax"
        is-text
        type="button"
        @click="addElement"
      >
        {{ addButtonText }}
      </v-button>

      <v-button v-else-if="localValue.length > 0" is-text type="button" @click="emptyFieldVisible = false">
        {{ removeButtonText }}
      </v-button>
    </div>
  </div>
</template>

<script>
import VSelect from '@/components/common/VSelect.vue'
import VFormError from '@/components/form/VFormError.vue'
import VButton from '@/components/common/VButton.vue'
import { isObject, cloneDeep } from '@/utils/common'

export default {
  name: 'VFormDynamicSelect',
  components: { VSelect, VFormError, VButton },
  inheritAttrs: false,
  props: {
    options: { type: Array, default: () => [] },
    value: { type: [Array, Object], default: () => [] },
    max: { type: Number, default: -1 },
    preventDuplicates: { type: Boolean, default: false },
    trackBy: { type: String, default: 'id' },
    isError: { type: Boolean, default: false },
    errors: { type: Array, default: () => [] },
    addButtonText: { type: String, default: '+ добавить еще один' },
    removeButtonText: { type: String, default: 'удалить' },
    hideControls: { type: Boolean, default: false }
  },
  data() {
    return {
      localValue: [],
      emptyFieldVisible: false
    }
  },
  computed: {
    addEmptyField() {
      return this.localValue.length === 0 || this.emptyFieldVisible
    },
    valuesToShow() {
      return this.addEmptyField ? [...this.localValue, null] : this.localValue
    },
    lessThanMax() {
      return this.max === -1 || this.valuesToShow.length < this.max
    },
    filteredOptions() {
      if (!this.preventDuplicates) return this.options
      return this.options.filter(opt => {
        if (isObject(opt)) return !this.localValue.find(lv => lv[this.trackBy] === opt[this.trackBy])
        return !this.localValue.includes(opt)
      })
    },
    inheritListeners() {
      const { input: _, ...inheritListeners } = this.$listeners
      return inheritListeners
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.localValue = val ? cloneDeep(val) : []
      }
    }
  },

  methods: {
    addElement() {
      this.emptyFieldVisible = true
    },
    updateValue(index, value) {
      if (value === null) {
        this.localValue.splice(index, 1)
      } else if (this.localValue.length - 1 < index) {
        this.localValue.push(value)
        this.emptyFieldVisible = false
      } else {
        this.localValue.splice(index, 1, value)
      }
      this.$emit('input', this.localValue)
    }
  }
}
</script>

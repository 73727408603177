<template>
  <v-dialog :visible.sync="localVisible" :title="title" class="shows-create-dialog">
    <validation-observer v-slot="{ handleSubmit }">
      <v-form body-scroll-lock-ignore @submit.prevent="handleSubmit(onSubmit)">
        <template #default>
          <v-form-row>
            <v-form-field label="КЛИЕНТ" :rules="rules.account" :error-message="errors.account">
              <template #default="{ validationErrors }">
                <v-select
                  v-model="show.account"
                  label="name"
                  append-to-body
                  :disabled="isCreation && !!clientId"
                  :filterable="false"
                  :is-error="!!validationErrors.length"
                  :options="clientOptions"
                  :loading="clientOptionsLoading"
                  @search="onClientSearch"
                />
              </template>
            </v-form-field>
          </v-form-row>
          <v-form-row v-if="isRoleAdmin">
            <v-form-field label="АГЕНТ" :rules="rules.agent" :error-message="errors.agent">
              <template #default="{ validationErrors }">
                <v-select
                  v-model="show.agent"
                  label="name"
                  append-to-body
                  :is-error="!!validationErrors.length"
                  :options="agentOptions"
                  :loading="agentOptionsLoading"
                />
              </template>
            </v-form-field>
          </v-form-row>

          <v-form-row>
            <v-form-field
              v-if="isCreation"
              label="ОБЪЕКТЫ НЕДВИЖИМОСТИ"
              :rules="rules.advert"
              separate-label
              hide-errors
              :error-message="errors.adverts"
            >
              <template #default="{ validationErrors }">
                <v-form-dynamic-select
                  v-model="show.adverts"
                  label="name"
                  append-to-body
                  :is-error="!!validationErrors.length"
                  :errors="validationErrors"
                  :options="advertOptions"
                  prevent-duplicates
                  :loading="advertOptionsLoading"
                  @search="onAdvertSearch"
                >
                </v-form-dynamic-select>
              </template>
            </v-form-field>
            <v-form-field
              v-else
              label="ОБЪЕКТ НЕДВИЖИМОСТИ"
              :rules="rules.advert"
              separate-label
              hide-errors
              :error-message="errors.advert"
            >
              <template #default="{ validationErrors }">
                <v-select
                  v-model="show.advert"
                  label="name"
                  append-to-body
                  :is-error="!!validationErrors.length"
                  :options="advertOptions"
                  @search="onAdvertSearch"
                ></v-select>
              </template>
            </v-form-field>
          </v-form-row>
          <v-form-row>
            <v-form-field label="ДАТА ПОКАЗА" :rules="rules.showDate" :error-message="errors.showDate">
              <template #default="{ validationErrors }">
                <v-input-date
                  v-model="show.showDate"
                  class="shows-create-dialog__date-input"
                  :clearable="false"
                  format="DD.MM.YYYY"
                  value-type="format"
                  :is-error="!!validationErrors.length"
                />
              </template>
            </v-form-field>
          </v-form-row>
          <v-form-row>
            <v-form-field label="ВРЕМЯ ПОКАЗА" :rules="rules.showTime" separate-label :error-message="errors.showTime">
              <template #default="{ validationErrors }">
                <div class="shows-create-dialog__time">
                  <v-select
                    v-model="show.showTimeHours"
                    class="shows-create-dialog__time-select"
                    label="name"
                    append-to-body
                    :is-error="!!validationErrors.length"
                    :options="$options.HOURS"
                    :clearable="false"
                  />
                  <span>:</span>
                  <v-select
                    v-model="show.showTimeMinutes"
                    class="shows-create-dialog__time-select"
                    label="name"
                    append-to-body
                    :is-error="!!validationErrors.length"
                    :options="$options.MINUTES"
                    :clearable="false"
                  />
                </div>
              </template>
            </v-form-field>
          </v-form-row>
          <v-form-row>
            <v-form-field label="КОММЕНТАРИЙ" :rules="rules.description" :error-message="errors.description">
              <template #default="{ validationErrors }">
                <v-textarea v-model="show.description" :is-error="!!validationErrors.length" :rows="3" />
              </template>
            </v-form-field>
          </v-form-row>
          <v-form-row>
            <v-image-uploader-with-preview
              class="shows-create-dialog__image-uploader"
              :photos="show.photos"
              @change="changePhotos"
              @add="addPhoto"
            >
              <template #preview="{ photo }">
                <v-act-of-viewing-preview
                  :blob-image="photo.image"
                  :url="photo.url"
                  :file-name="photo.fileName"
                  :progress="photo.progress"
                  @remove="removePhoto(photo)"
                ></v-act-of-viewing-preview>
              </template>
              <template #input-text>Загрузить фото акта просмотра</template>
            </v-image-uploader-with-preview>
          </v-form-row>
        </template>
        <template #footer>
          <v-button
            v-if="!isCreation && showData.status !== 'canceled'"
            class="shows-create-dialog__cancel-button"
            @click="cancelShow"
            >Отменить показ</v-button
          >
          <v-button :disabled="!isCreation && isFieldsNotChanged" primary type="submit">Сохранить</v-button>
        </template>
      </v-form>
    </validation-observer>
  </v-dialog>
</template>

<script>
import VDialog from '@/components/common/VDialog.vue'
import VForm from '@/components/form/VForm.vue'
import VFormRow from '@/components/form/VFormRow.vue'
import VFormField from '@/components/form/VFormField.vue'
import VButton from '@/components/common/VButton.vue'
import VSelect from '@/components/common/VSelect.vue'
import VFormDynamicSelect from '@/components/form/VFormDynamicSelect.vue'
import { advertsService, agentsService, customersService } from '@/services/http'
import { formatAccountName, formatAdvertName, formatAgentObject, formatClientObject } from '@/utils/formatters'
import { cloneDeep, getFirstErrorForFields, isFieldsNotChanged } from '@/utils/common'
import { loadingService } from '@/services/loading'
import { mapGetters } from 'vuex'
import { MODULE_SESSION } from '@/store/modules/session/session.types'
import VInputDate from '@/components/common/VInputDate.vue'
import VTextarea from '@/components/common/VTextarea.vue'
import { HOURS, MINUTES } from '@/constants/timeOptions'
import VImageUploaderWithPreview from '@/components/common/VImageUploaderWithPreview.vue'
import VActOfViewingPreview from '@/components/common/VActOfViewingPreview.vue'

function getDefaultShowData() {
  return {
    account: null,
    agent: null,
    showDate: null,
    showTimeHours: '00',
    showTimeMinutes: '00',
    advert: null,
    adverts: [],
    description: '',
    photos: [],
    photosDelete: [],
    photosAdd: []
  }
}

export default {
  HOURS,
  MINUTES,
  name: 'ShowsCreateDialog',
  components: {
    VFormDynamicSelect,
    VActOfViewingPreview,
    VImageUploaderWithPreview,
    VTextarea,
    VInputDate,
    VDialog,
    VFormField,
    VFormRow,
    VButton,
    VForm,
    VSelect
  },
  props: {
    visible: { type: Boolean, required: true },
    showData: { type: Object, default: () => null },
    clientId: { type: String, default: '' }
  },
  data: () => {
    return {
      show: getDefaultShowData(),
      loading: false,
      errors: {},
      advertOptionsLoading: false,
      agentOptionsLoading: false,
      clientOptionsLoading: false,
      advertOptions: [],
      agentOptions: [],
      clientOptions: []
    }
  },
  computed: {
    isFieldsNotChanged() {
      return isFieldsNotChanged(this.showData, this.show, { isAdmin: this.isRoleAdmin, objectType: 'show' })
    },
    ...mapGetters({
      isRoleAdmin: `${MODULE_SESSION}/isRoleAdmin`
    }),
    rules() {
      return {
        agent: `${this.isCreation ? 'required' : ''}`,
        showDate: `${this.isCreation ? 'required' : ''}`,
        showTime: `${this.isCreation ? 'required' : ''}`,
        advert: `${this.isCreation ? 'required' : ''}`,
        account: `${this.isCreation ? 'required' : ''}`
      }
    },
    isCreation() {
      return !(this.showData && this.showData.id)
    },
    title() {
      return this.isCreation ? 'Новый показ' : 'Редактировать показ'
    },
    localVisible: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {
    loading(val) {
      loadingService.setGlobalLoading(val)
    },
    showData: {
      immediate: true,
      handler() {
        this.resetLocalState()
      }
    },
    visible: {
      immediate: true,
      handler(val) {
        if (val) this.resetLocalState()
      }
    }
  },
  created() {
    if (this.isRoleAdmin) {
      this.selectAgents()
    }
    this.selectClients()
    this.selectAdverts()
  },
  methods: {
    cancelShow() {
      this.loading = true
      agentsService
        .updateShow({ id: this.showData.id, showStatus: 'canceled' })
        .then(() => {
          this.$emit('submitted')
          this.localVisible = false
        })
        .catch(error => {
          const flatErrors = { ...error.details, ...error.details.account }
          this.errors = getFirstErrorForFields(flatErrors)
        })
        .finally(() => {
          this.loading = false
        })
    },
    changePhotos(photos) {
      this.show.photos = photos
    },
    addPhoto(photo) {
      this.show.photos.push(photo)
      this.show.photosAdd.push(photo.id)
    },
    removePhoto(photo) {
      const { photosAdd, photos } = this.show
      if (this.isOldPhoto(photo)) {
        this.show.photosDelete.push(photo.id)
      }
      this.show.photosAdd = photosAdd.filter(p => p.id !== photo.id)
      this.show.photos = photos.filter(p => p.id !== photo.id)
    },
    isOldPhoto({ id }) {
      return !!this.showData?.photos?.find(photo => photo.id === id)
    },

    selectClients(query) {
      this.clientOptionsLoading = true
      return customersService
        .select(query)
        .then(results => {
          this.clientOptions = results
        })
        .finally(() => {
          this.clientOptionsLoading = false
        })
    },
    selectAgents() {
      this.agentOptionsLoading = true
      return agentsService
        .select()
        .then(agentList => {
          this.agentOptions = agentList.map(agent => formatAgentObject(agent))
        })
        .finally(() => {
          this.agentOptionsLoading = false
        })
    },
    selectAdverts(query) {
      this.advertOptionsLoading = true
      return advertsService
        .select(query)
        .then(advertsList => {
          this.advertOptions = advertsList.map(advert => {
            const { name, roundedPrice } = advert
            return {
              ...advert,
              name: `${name} - ${roundedPrice}`
            }
          })
        })
        .finally(() => {
          this.advertOptionsLoading = false
        })
    },
    resetLocalState() {
      const time = this.showData?.showTime?.split(':') || null
      const [hours, minutes] = time || ['00', '00']

      this.show = {
        ...getDefaultShowData(),
        ...cloneDeep(this.showData),
        showTimeHours: hours,
        showTimeMinutes: minutes,
        advert: { ...this.showData?.advert, name: formatAdvertName(this.showData?.advert, this.isCreation) },
        account: this.showData?.account
          ? { ...this.showData?.account, name: formatAccountName(this.showData, this.isCreation) }
          : { name: formatAccountName(this.showData, this.isCreation) }
      }
      if (this.isCreation && this.clientId) {
        customersService.getCustomerById(this.clientId).then(obj => {
          this.show.account = formatClientObject(obj)
        })
      }
      this.errors = {}
    },
    onSubmit() {
      this.loading = true
      const showData = {
        ...this.show,
        showTime: `${this.show.showTimeHours}:${this.show.showTimeMinutes}`
      }

      agentsService[this.isCreation ? 'createShow' : 'updateShow'](
        this.isCreation
          ? showData
          : {
              ...showData,
              id: this.showData.id,
              changedReminderFields: {
                comment: showData.description !== this.showData.description,
                date: showData.showDate !== this.showData.showDate,
                time: [showData.showTimeHours, showData.showTimeMinutes].join(':') !== this.showData.showTime
              }
            }
      )
        .then(() => {
          this.$emit('submitted')
          this.localVisible = false
        })
        .catch(error => {
          const flatErrors = { ...error.details, ...error.details.account }
          this.errors = getFirstErrorForFields(flatErrors)
        })
        .finally(() => {
          this.loading = false
        })
    },
    onAdvertSearch(query, loading) {
      loading(true)
      this.selectAdverts(query).finally(() => {
        loading(false)
      })
    },
    onClientSearch(query, loading) {
      loading(true)
      this.selectClients(query).finally(() => {
        loading(false)
      })
    }
  }
}
</script>

<template>
  <button
    :class="['v-tab', { 'v-tab--primary': rootTabs.primary }, isActive && 'v-tab--active']"
    type="button"
    @click="handleClick"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: 'VTab',
  props: {
    name: { type: String, required: true }
  },
  inject: ['rootTabs'],
  data() {
    return {
      isActive: false
    }
  },
  created() {
    this.rootTabs.addTab(this)
  },
  methods: {
    handleClick() {
      this.rootTabs.changeActiveTab(this)
    },
    activate() {
      this.isActive = true
    },
    deactivate() {
      this.isActive = false
    }
  }
}
</script>

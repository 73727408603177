<template>
  <div :class="['v-tabs', { 'v-tabs--primary': primary }]" body-scroll-lock-ignore>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'VTabs',
  props: {
    value: { type: String, default: '' },
    primary: { type: Boolean, default: false }
  },
  provide() {
    return {
      rootTabs: this
    }
  },
  data() {
    return {
      tabs: [],
      activeTab: null
    }
  },
  watch: {
    value: {
      handler(val) {
        this.setActiveTabByName(val)
      }
    }
  },
  mounted() {
    if (this.tabs.length) {
      if (this.value) {
        this.setActiveTabByName(this.value)
      } else {
        this.changeActiveTab(this.tabs[0])
      }
    }
  },
  methods: {
    addTab(tab) {
      this.tabs.push(tab)
    },
    setActiveTab(tab) {
      tab.activate()
      this.activeTab = tab
    },
    setActiveTabByName(name) {
      const tab = this.tabs.find(el => el.name === name)
      if (tab) this.changeActiveTab(tab)
    },
    changeActiveTab(tab) {
      if (this.activeTab) {
        this.activeTab.deactivate()
      }
      this.setActiveTab(tab)
      this.$emit('input', tab.name)
    }
  }
}
</script>

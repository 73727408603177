<template>
  <v-dialog :visible.sync="localVisible" title="ФИЛЬТРЫ">
    <div class="dashboard-filters-mobile">
      <template v-if="!customRangeSectionVisible">
        <h4 class="dashboard-filters-mobile__text">СОРТИРОВАТЬ ПО</h4>

        <div class="dashboard-filters-mobile__radio-row" body-scroll-lock-ignore>
          <v-radio
            :model-value="filtersParams.dateRangeType"
            class="dashboard-filters-mobile__radio"
            :value="$options.CUSTOM_DATE_TYPE"
            @click="customRangeSectionVisible = true"
            >{{ customDateText }}</v-radio
          >
          <v-radio
            v-for="(label, range) of $options.DATE_RANGE_TYPES"
            :key="range"
            v-model="filtersParams.dateRangeType"
            class="dashboard-filters-mobile__radio"
            :value="range"
            >{{ label }}</v-radio
          >
        </div>
        <h4 class="dashboard-filters-mobile__text">КАТЕГОРИИ ОБЪЕКТОВ</h4>

        <div class="dashboard-filters-mobile__radio-row" body-scroll-lock-ignore>
          <v-radio
            v-for="category of categoryOptions"
            :key="category.id"
            v-model="filtersParams.categoryId"
            class="dashboard-filters-mobile__radio"
            :value="category.id"
            >{{ category.label }}</v-radio
          >
        </div>
        <div class="dashboard-filters-mobile__actions">
          <v-button class="dashboard-filters-mobile__apply" primary @click="apply">Сохранить</v-button>
          <v-button class="dashboard-filters-mobile__reset" @click="resetLocalState">Сброс</v-button>
        </div>
      </template>
      <template v-else>
        <button class="dashboard-filters-mobile__button-back" @click="resetCustomDateRange">
          <v-icon-arrow-left />
          Назад
        </button>

        <v-form-field label="ОТ" class="dashboard-filters-mobile__date-range " separate-label>
          <v-input-date
            v-model="filtersParams.dateFrom"
            class="dashboard-filters-mobile__input-date"
            format="YYYY-MM-DD"
            inline
          />
        </v-form-field>
        <v-form-field label="ДО" class="dashboard-filters-mobile__date-range" separate-label>
          <v-input-date
            v-model="filtersParams.dateTo"
            class="dashboard-filters-mobile__input-date"
            format="YYYY-MM-DD"
            inline
          />
        </v-form-field>

        <v-button key="set" class="dashboard-filters-mobile__apply" @click="setCustomDateRange">
          Выбрать
        </v-button>
      </template>
    </div>
  </v-dialog>
</template>

<script>
import VButton from '@/components/common/VButton.vue'
import VIconArrowLeft from '@/components/icons/VArrowLeft.vue'
import VRadio from '@/components/common/VRadio.vue'
import VDialog from '@/components/common/VDialog.vue'
import VInputDate from '@/components/common/VInputDate.vue'
import VFormField from '@/components/form/VFormField.vue'
import { CUSTOM_DATE_TYPE, DATE_RANGE_TYPES, SIX_MONTH_TYPE } from '@/constants/dateRangeTypes'
import { formatDatePreview } from '@/utils/formatters'
import { advertsService } from '@/services/http'

const DEFAULT_CATEGORY_ID = 0

function generateDefaultFilterParams() {
  return {
    dateFrom: undefined,
    dateTo: undefined,
    dateRangeType: undefined,
    isSimilarRange: undefined,
    categoryId: undefined
  }
}
export default {
  DATE_RANGE_TYPES,
  CUSTOM_DATE_TYPE,
  DEFAULT_DATE_TYPE: SIX_MONTH_TYPE,
  name: 'DashboardGeneralFiltersMobile',
  components: { VButton, VFormField, VDialog, VIconArrowLeft, VRadio, VInputDate },
  props: {
    visible: { type: Boolean, required: true },
    defaultFilters: { type: Object, default: () => {} }
  },
  data() {
    return {
      filtersParams: generateDefaultFilterParams(),
      categoryOptions: [],
      customRangeSectionVisible: false
    }
  },
  computed: {
    localVisible: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },
    customDateText() {
      return formatDatePreview(this.filtersParams.dateFrom, this.filtersParams.dateTo) || 'Выберите дату'
    }
  },
  watch: {
    defaultFilters: {
      immediate: true,
      deep: true,
      handler(newFilters) {
        if (!newFilters.categoryId) this.filtersParams = { ...newFilters, categoryId: DEFAULT_CATEGORY_ID }
        else this.filtersParams = { ...newFilters }
      }
    }
  },
  created() {
    this.selectCategory()
  },
  methods: {
    close() {
      this.localVisible = false
    },
    apply() {
      const categoryId = this.filtersParams.categoryId === DEFAULT_CATEGORY_ID ? null : this.filtersParams.categoryId
      this.$emit('change', {
        ...this.filtersParams,
        categoryId
      })
      this.close()
    },
    resetLocalState() {
      this.filtersParams = generateDefaultFilterParams()
    },
    setCustomDateRange() {
      if (this.filtersParams.dateFrom || this.filtersParams.dateTo) {
        this.filtersParams.dateRangeType = this.$options.CUSTOM_DATE_TYPE
        this.customRangeSectionVisible = false
      }
    },
    resetCustomDateRange() {
      this.filtersParams = {
        ...this.filtersParams,
        dateFrom: null,
        dateTo: null
      }
      this.customRangeSectionVisible = false
    },
    selectCategory() {
      return advertsService.selectCategory().then(categories => {
        categories.unshift({
          name: 'Все объекты',
          id: DEFAULT_CATEGORY_ID
        })
        this.categoryOptions = categories.map(category => {
          return {
            label: category.name,
            id: category.id
          }
        })
      })
    }
  }
}
</script>

<template>
  <div :class="['v-drawer', classes]">
    <div v-scroll-lock="visible" class="v-drawer__content">
      <slot />
    </div>
    <fade-transition>
      <div v-if="!withoutBackground && visible" class="v-drawer__background" @click="close"></div>
    </fade-transition>
  </div>
</template>

<script>
import FadeTransition from '@/components/transitions/FadeTransition.vue'
import ScrollLock from '@/directives/body-scroll-lock'

export default {
  name: 'VDrawer',
  components: { FadeTransition },
  directives: {
    ScrollLock
  },
  props: {
    visible: { type: Boolean, required: true },
    withoutBackground: { type: Boolean, default: false },
    position: {
      type: String,
      default: 'left',
      validator: position => ['left', 'right'].includes(position)
    }
  },
  computed: {
    classes() {
      return {
        'v-drawer--right-side': this.position === 'right',
        'v-drawer--visible': this.visible
      }
    }
  },
  methods: {
    close() {
      this.$emit('update:visible', false)
    }
  }
}
</script>

<template>
  <v-input
    :value="localValue"
    :mask="$options.PHONE_MASK"
    inputmode="numeric"
    v-bind="$attrs"
    type="text"
    v-on="inheritListeners"
    @input="updatePhoneValue"
  >
    <template #append>
      <slot name="append"></slot>
    </template>
  </v-input>
</template>

<script>
import VInput from '@/components/common/VInput.vue'
import { PHONE_MASK } from '@/constants/textMask'

export default {
  PHONE_MASK,
  name: 'VInputPhone',
  components: { VInput },
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      localValue: ''
    }
  },
  computed: {
    inheritListeners() {
      const { input: _input, ...restListeners } = this.$listeners
      return restListeners
    },
    phoneValue() {
      return this.localValue.replace(/[^\d]+/g, '').substr(0, 11)
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.localValue = newValue
      }
    }
  },
  methods: {
    updatePhoneValue(value) {
      this.localValue = value
      this.$emit('input', this.phoneValue)
    }
  }
}
</script>

<template>
  <match-media v-slot="{ mobile }">
    <div class="dashboard-shows">
      <v-button-go-back
        v-if="mobile && localSectionActiveMenuItem"
        class="dashboard-shows__button-go-back"
        @click="goBack"
      />
      <h3 v-if="sectionActiveMenuItem" class="dashboard-shows__title">Анализ показов</h3>

      <!-- line-chart section -->
      <div v-if="!localSectionActiveMenuItem" class="dashboard-shows__line-chart-section">
        <!-- Shows chart -->
        <v-chart-with-filters
          class="dashboard-shows__chart"
          type="line"
          title="Показы"
          :count="shows.total"
          :datasets="shows.datasets"
          :labels="shows.labels"
          :titles="shows.titles"
          :filters="shows.filters"
          with-similar-range
          with-plan-fact
          with-date-filter
          with-agent-select
          @change-filters="changeShowsFilters"
        />

        <!-- Shows categories chart -->
        <v-chart-with-filters
          class="dashboard-shows__chart"
          type="line"
          title="Показы. Категории"
          :datasets="showsCategories.datasets"
          :labels="showsCategories.labels"
          :filters="showsCategories.filters"
          :titles="showsCategories.titles"
          :chart-options="{ legend: true }"
          with-similar-range
          with-date-filter
          with-agent-select
          @change-filters="changeShowsCategoriesFilters"
        />

        <!-- Agents chart -->
        <v-chart-with-filters
          class="dashboard-shows__chart"
          type="bar"
          title="Показы. Агенты"
          :datasets="showsAgents.datasets"
          :labels="showsAgents.labels"
          :chart-options="{ legend: true }"
        />
      </div>

      <!-- agent-metrics section -->
      <div
        v-if="!mobile || localSectionActiveMenuItem === $options.TOP_AGENTS"
        class="dashboard-shows__agent-metrics-section"
      >
        <agent-metrics-list
          title="Лучшие агенты. Показы."
          :list="agentBestList"
          :date="dateShowsAgentBestList"
          is-month-select-visible
          class="dashboard-shows__agent-metrics-list dashboard-shows__agent-metrics-list--left"
          @change="refetchBestAgentList"
        ></agent-metrics-list>
        <agent-metrics-list
          title="Неэффективные агенты. Показы."
          :list="agentIneffectiveList"
          :date="dateShowsAgentIneffectiveList"
          is-month-select-visible
          class="dashboard-shows__agent-metrics-list dashboard-shows__agent-metrics-list--right"
          @change="refetchIneffectiveAgentList"
        ></agent-metrics-list>
      </div>

      <div v-if="mobile && !localSectionActiveMenuItem" class="dashboard-shows__mobile-menu">
        <v-menu v-model="localSectionActiveMenuItem">
          <v-menu-item :name="$options.TOP_AGENTS">
            <span class="dashboard-shows__menu-item">
              ТОП агентов
            </span>
          </v-menu-item>
        </v-menu>
      </div>
    </div>
  </match-media>
</template>

<script>
import { MatchMedia } from 'vue-component-media-queries'
import VChartWithFilters from '@/components/common/VChartWithFilters.vue'
import AgentMetricsList from '@/components/Agent/Metrics/List.vue'
import VMenu from '@/components/common/VMenu.vue'
import VMenuItem from '@/components/common/VMenuItem.vue'
import VButtonGoBack from '@/components/common/VButtonGoBack.vue'
import { advertsService, statisticService } from '@/services/http'
import { loadingService } from '@/services/loading'
import { abbreviatedDate, createChartDataset } from '@/utils/common'
import { CUSTOM_DATE_TYPE, SIX_MONTH_TYPE, THIS_MONTH_TYPE } from '@/constants/dateRangeTypes'
import { TOP_AGENTS } from '@/constants/dashboard'
import { getColorByIndex, getLightColorByIndex } from '../common/VChart/utils'

export default {
  TOP_AGENTS,
  name: 'DashboardShows',
  components: {
    MatchMedia,
    VChartWithFilters,
    AgentMetricsList,
    VMenu,
    VMenuItem,
    VButtonGoBack
  },
  props: {
    sectionActiveMenuItem: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      shows: {
        filters: {
          dateFrom: null,
          dateTo: null,
          dateRangeType: SIX_MONTH_TYPE,
          isSimilarRange: false,
          planFact: false,
          agentId: null
        },
        total: 0,
        datasets: [],
        labels: [],
        titles: {
          current: [],
          similar: []
        }
      },
      showsCategories: {
        filters: {
          dateFrom: null,
          dateTo: null,
          dateRangeType: SIX_MONTH_TYPE,
          isSimilarRange: false,
          agentId: null
        },
        datasets: [],
        labels: [],
        titles: {
          current: [],
          similar: []
        }
      },
      showsAgents: {
        datasets: [],
        labels: []
      },

      categories: [],
      dateShowsAgentBestList: new Date(),
      dateShowsAgentIneffectiveList: new Date(),
      agentBestList: [],
      agentIneffectiveList: []
    }
  },
  inject: ['graphsMainColor'],
  computed: {
    localSectionActiveMenuItem: {
      get() {
        return this.sectionActiveMenuItem
      },
      set(newValue) {
        this.$emit('update:sectionActiveMenuItem', newValue)
      }
    }
  },
  created() {
    loadingService.setViewLoading(true)

    this.fetchCategories()
      .then(() => {
        const showsRequest = this.fetchShowsGraph()
        const showsCategoriesRequest = this.fetchShowsCategoriesGraph()
        const showsAgentsRequest = this.fetchShowsAgentsGraph()
        const agentBestListRequest = this.fetchBestAgentList()
        const agentIneffectiveListRequest = this.fetchIneffectiveAgentList()

        return Promise.all([
          showsRequest,
          showsCategoriesRequest,
          showsAgentsRequest,
          agentBestListRequest,
          agentIneffectiveListRequest
        ])
      })
      .finally(() => {
        loadingService.setViewLoading(false)
      })
  },
  methods: {
    fetchCategories() {
      return advertsService.selectCategory().then(data => {
        this.categories = data
      })
    },

    // fetch Graph
    fetchShowsGraph() {
      return statisticService.getShowsGraph(this.shows.filters).then(data => {
        const datasets = []

        const defaultDataset = createChartDataset({
          data: data.graph,
          color: this.graphsMainColor,
          label: 'Показы',
          filled: true
        })
        datasets.push(defaultDataset)

        if (data.similarRangeGraph) {
          const similarDataset = createChartDataset({
            data: data.similarRangeGraph,
            color: 'green',
            label: 'Показы в похожем периоде'
          })
          datasets.push(similarDataset)
          this.shows.titles.similar = data.similarRangeGraph.map(i => i.date)
        } else {
          this.shows.titles.similar = []
        }

        if (data.planGraph) {
          const planDataset = createChartDataset({ data: data.planGraph, color: 'blue', label: 'План показов' })
          datasets.push(planDataset)
        }

        this.shows.datasets = datasets
        this.shows.labels = data.graph.map(i => i.date)
        this.shows.titles.current = data.graph.map(i => i.date)
        this.shows.total = data.sumOfValues

        if (
          this.shows.filters.dateRangeType !== THIS_MONTH_TYPE &&
          this.shows.filters.dateRangeType !== CUSTOM_DATE_TYPE
        ) {
          this.shows.labels = abbreviatedDate(this.shows.labels)
          this.shows.titles.current = abbreviatedDate(this.shows.titles.current)
          this.shows.titles.similar = abbreviatedDate(this.shows.titles.similar)
        }
      })
    },
    fetchShowsCategoriesGraph() {
      return statisticService.getShowsCategoriesGraph(this.showsCategories.filters).then(data => {
        const datasets = []

        data.graphs.forEach(({ graph, category }, index) => {
          const label = this.categories.find(cat => cat.id === category).name
          const dataset = createChartDataset({ data: graph, color: getColorByIndex(index), label })
          datasets.push(dataset)
        })

        if (data.similarRangeGraphs) {
          data.similarRangeGraphs.forEach(({ graph, category }) => {
            const label = this.categories.find(cat => cat.id === category).name
            const index = datasets.findIndex(d => d.label === label)

            const dataset = createChartDataset({
              data: graph,
              color: getLightColorByIndex(index),
              label: `%${label}`
            })
            datasets.push(dataset)
            this.showsCategories.titles.similar = data.similarRangeGraphs.length
              ? data.similarRangeGraphs[0].graph.map(i => i.date)
              : []
          })
        } else {
          this.showsCategories.titles.similar = []
        }

        this.showsCategories.datasets = datasets
        this.showsCategories.labels = data.graphs.length ? data.graphs[0].graph.map(i => i.date) : []
        this.showsCategories.titles.current = data.graphs.length ? data.graphs[0].graph.map(i => i.date) : []

        if (
          this.showsCategories.filters.dateRangeType !== THIS_MONTH_TYPE &&
          this.showsCategories.filters.dateRangeType !== CUSTOM_DATE_TYPE
        ) {
          this.showsCategories.labels = abbreviatedDate(this.showsCategories.labels)
          this.showsCategories.titles.current = abbreviatedDate(this.showsCategories.titles.current)
          this.showsCategories.titles.similar = abbreviatedDate(this.showsCategories.titles.similar)
        }
      })
    },
    fetchShowsAgentsGraph() {
      return statisticService.getShowsAgentsGraph({ dateRangeType: SIX_MONTH_TYPE }).then(data => {
        const datasets = []

        data.graphs.forEach(({ graph, agent }, index) => {
          const label = agent.name
          const dataset = createChartDataset({ data: graph, color: getColorByIndex(index), label })
          datasets.push(dataset)
        })

        this.showsAgents.datasets = datasets

        const labels = data.graphs.length ? data.graphs[0].graph.map(i => i.date) : []
        this.showsAgents.labels = abbreviatedDate(labels)
      })
    },

    // refetch Graph
    refetchShowsGraph() {
      loadingService.setViewLoading(true)

      this.fetchShowsGraph().finally(() => {
        loadingService.setViewLoading(false)
      })
    },
    refetchShowsCategoriesGraph() {
      loadingService.setViewLoading(true)

      this.fetchShowsCategoriesGraph().finally(() => {
        loadingService.setViewLoading(false)
      })
    },

    fetchBestAgentList(params) {
      return statisticService.getBestAgentsShowsList(params).then(list => {
        this.agentBestList = list
      })
    },
    fetchIneffectiveAgentList(params) {
      return statisticService.getIneffectiveAgentsShowsList(params).then(list => {
        this.agentIneffectiveList = list
      })
    },

    refetchBestAgentList(newDate) {
      this.dateShowsAgentBestList = newDate
      const month = newDate.getMonth() + 1
      const year = newDate.getFullYear()
      loadingService.setViewLoading(true)
      this.fetchBestAgentList({ month, year }).finally(() => {
        loadingService.setViewLoading(false)
      })
    },

    refetchIneffectiveAgentList(newDate) {
      this.dateShowsAgentIneffectiveList = newDate
      const month = newDate.getMonth() + 1
      const year = newDate.getFullYear()

      loadingService.setViewLoading(true)
      this.fetchIneffectiveAgentList({ month, year }).finally(() => {
        loadingService.setViewLoading(false)
      })
    },

    changeShowsFilters(filters) {
      this.shows.filters = filters
      this.refetchShowsGraph()
    },
    changeShowsCategoriesFilters(filters) {
      this.showsCategories.filters = filters
      this.refetchShowsCategoriesGraph()
    },
    goBack() {
      this.localSectionActiveMenuItem = undefined
    }
  }
}
</script>

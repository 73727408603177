<template>
  <div class="v-breadcrumbs">
    <div v-for="(item, index) in items" :key="index" class="v-breadcrumbs__item-wrapper">
      <router-link v-if="item.to && !item.disabled && !item.isOuterLink" class="v-breadcrumbs__item" :to="item.to">{{
        item.text
      }}</router-link>
      <a v-else-if="item.to && !item.disabled && item.isOuterLink" class="v-breadcrumbs__item" :href="item.to">{{
        item.text
      }}</a>
      <span v-else class="v-breadcrumbs__item v-breadcrumbs__item--disabled">{{ item.text }}</span>
      <span v-if="index < items.length - 1" class="v-breadcrumbs__separator">{{ separator }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VBreadcrumbs',
  props: {
    // array of [{to: Object, text: String, disabled: Boolean}, ...]
    items: { type: Array, required: true },
    separator: {
      type: String,
      default: '/',
      validator(value) {
        return ['/', '•'].indexOf(value) !== -1
      }
    }
  }
}
</script>
